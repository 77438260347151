// CommentList.tsx
import React from 'react';
import Comment from './comment.tsx';
import ReplyForm from '../replyForm.tsx';

interface CommentListProps {
  comments: Comment[];
  onReplyClick?: (commentId: number) => void; // Callback for reply button click
  selectedCommentId?: number | null;
  postId: number;
}

const CommentList: React.FC<CommentListProps> = ({ comments, postId, onReplyClick, selectedCommentId }) => {
  // Filter out comments with parentId !== null
  const filteredComments = comments.filter(comment => comment.parentId === null);

  return (
    <div className="comment-list">
      {filteredComments.map((comment, index) => (
        <React.Fragment key={comment.id}>
          <Comment
            comment={comment}
            onReplyClick={() => onReplyClick && onReplyClick(comment.id)}
            isReplying={selectedCommentId === comment.id}
            allComments={comments} // Pass the original comments array
          />
          {selectedCommentId === comment.id && (
            <ReplyForm
              onCancelReply={() => onReplyClick && onReplyClick(null)}
              replyToComment={comment.id}
              postId={postId}
            />
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default CommentList;
