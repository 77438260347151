import { useEffect } from 'react';

function usePreventZoom(scrollCheck = true, keyboardCheck = true) {
  useEffect(() => {
    const handleKeydown = (e) => {
      if (keyboardCheck && e.ctrlKey && (e.code === 'Equal' || e.code === 'NumpadAdd' || e.code === 'Minus' || e.code === 'NumpadSubtract')) {
        e.preventDefault();
      }
    };

    const handleWheel = (e) => {
      if (scrollCheck && e.ctrlKey) {
        e.preventDefault();

        // Smoothly scroll both horizontally and vertically based on wheel delta
        document.documentElement.scrollTo({
          left: document.documentElement.scrollLeft + e.deltaX,
          top: document.documentElement.scrollTop + e.deltaY,
          behavior: 'smooth',
        });
      }
    };

    document.addEventListener('keydown', handleKeydown);
    document.addEventListener('wheel', handleWheel, { passive: false });

    return () => {
      document.removeEventListener('keydown', handleKeydown);
      document.removeEventListener('wheel', handleWheel);
    };
  }, [scrollCheck, keyboardCheck]);
}

export default usePreventZoom;
