// index.tsx
import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import Home from './pages/Home.tsx';
import Blog from './pages/blog.tsx';
import SAFMCPART1 from './pages/blogDetail/safmc/safmcPart1.tsx';
import SAFMCPART2 from './pages/blogDetail/safmc/safmcPart2.tsx';
import SAFMCPART3 from './pages/blogDetail/safmc/safmcPart3.tsx';
import SAFMCPART4 from './pages/blogDetail/safmc/safmcPart4.tsx';
import SAFMCPART5 from './pages/blogDetail/safmc/safmcPart5.tsx';
import SAFMCPART6 from './pages/blogDetail/safmc/safmcPart6.tsx';
import SAFMCPART7 from './pages/blogDetail/safmc/safmcPart7.tsx';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/safmc',
    element: <Blog />,
  },
  {
    path: '/safmc/safmc-part1',
    element: <SAFMCPART1 />,
  },
  {
    path: '/safmc/safmc-part2',
    element: <SAFMCPART2 />,
  },
  {
    path: '/safmc/safmc-part3',
    element: <SAFMCPART3 />,
  },
  {
    path: '/safmc/safmc-part4',
    element: <SAFMCPART4 />,
  },
  {
    path: '/safmc/safmc-part5',
    element: <SAFMCPART5 />,
  },
  {
    path: '/safmc/safmc-part6',
    element: <SAFMCPART6 />,
  },
  {
    path: '/safmc/safmc-part7',
    element: <SAFMCPART7 />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RouterProvider router={router} />
);

reportWebVitals();
