import React, { useEffect, useState } from 'react';
import { Turn as Hamburger } from 'hamburger-react';
import Logo from '../assets/WebsiteLogoNoBG.webp';
import {FaAngleDoubleLeft} from 'react-icons/fa';
import { Link } from 'react-scroll';
import { Link as RouterLink } from 'react-router-dom';

const Header: React.FC = () => {
  const Links: Link[] = [
    { name: "MOVIE", link: "safmc-after-movie" },
    { name: "STORY", link: "safmc-story-telling" },
  ];

  const [isMd, setIsMd] = useState(window.matchMedia('(min-width: 67.5rem)').matches);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(min-width: 67.5rem)');
    const updateIsMd = () => {
      setIsMd(mediaQuery.matches);
    };

    mediaQuery.addListener(updateIsMd);
    return () => {
      mediaQuery.removeListener(updateIsMd);
    };
  }, []);

  const [open, setOpen] = useState<boolean>(false);
  const [isBackToHomeHovered, setIsBackToHomeHovered] = useState(false);

  const offset = isMd ? -100 : -100;
  const handleLogoClick = () => {
    window.location.href = 'https://rifqifarhansyah.id';
  };

  return (
    <div className='shadow-md w-screen fixed top-0 left-0 z-[10000]'>
      <div className='md:flex items-center justify-between bg-white py-4 md:px-10 px-7'>
        {/* logo section */}
        <div className='font-bold text-sm cursor-pointer flex items-center gap-1' onClick={handleLogoClick}>
          <img src={Logo} alt="Rifqi Website Logo" className='w-12 h-12' width="12" height="12" loading="lazy" />
        </div>
        {/* Menu icon */}
        <div className="absolute right-6 top-4 cursor-pointer md:hidden my-auto">
          <Hamburger toggled={open} toggle={setOpen} />
        </div>
        {/* link items */}
        <ul className={`md:flex md:items-center md:pb-0 pb-8 md:shadow-none shadow-md absolute md:static bg-white md:z-auto z-[-1] left-0 w-full md:w-auto md:pl-0 pl-9 transition-all duration-300 ${open ? 'top-12' : 'top-[-490px]'}`}>
          {Links.map((link) => (
            <li key={link.name} className='md:ml-8 md:my-0 my-7 font-semibold flex items-center font-condensed tracking-wider'>
              {link.name === 'BLOG' ? (
                <RouterLink to={link.link} className='text-sm text-gray-800 hover:text-textRed duration-500'>{link.name}</RouterLink>
              ) : (
                <Link to={link.link} smooth={true} duration={200} offset={offset}>
                  <a href={link.link} className='text-sm text-gray-800 hover:text-textRed duration-500' title={`Go to ${link.name}`}>{link.name}</a>
                </Link>
              )}
            </li>
          ))}
          {/* button */}
          <li className='md:ml-8 md:my-0 hover:text-primary '>
            {open ? 
            <a
              href="https://rifqifarhansyah.id"
              title='Back to Home'
              className={`inline-flex items-center font-condensed px-5 py-1 border-opacity-25 border text-sm font-thin hover:bg-hoverDarkGrey hover:border-opacity-50 rounded-full uppercase focus:outline-none w-11/12 justify-center mr-8`}
            >
              <FaAngleDoubleLeft className='w-3 h-3 text-rgba-28-5-179 mr-2 ' />Back to HOME
            </a>
            :
            <a
              href="https://rifqifarhansyah.id"
              title='Back to Home'
              className={`inline-flex items-center font-condensed px-5 py-1 border-opacity-25 border text-sm font-thin hover:bg-hoverDarkGrey hover:border-opacity-50 rounded-full uppercase focus:outline-none relative `}
              onMouseEnter={() => setIsBackToHomeHovered(true)}
              onMouseLeave={() => setIsBackToHomeHovered(false)}
            >
              <FaAngleDoubleLeft className={`w-3 h-3 text-rgba-28-5-179 ml-2 absolute left-0 transition-all duration-300 ${isBackToHomeHovered ? 'opacity-100' : 'opacity-0'}`} />
              <span className={`transition-all duration-300 ${isBackToHomeHovered ? 'pl-2 translate-x-1' : 'pl-1'}`}>Back to Home</span>
            </a>
            }
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Header;
