import React, { useEffect, useState } from 'react';
import LoadingBar from 'react-top-loading-bar';
import { useTransition, animated } from 'react-spring';
import Navbar from '../components/navbar.tsx';
import '../App.css';
import Footer from '../components/footer.tsx';
import { FaArrowCircleUp } from 'react-icons/fa';

import CourseProject from './courseproject.tsx';
import Award from './awards.tsx';
import News from './news.tsx';
import Profile from './profile.tsx';
import External from './external.tsx';
import usePreventZoom from '../preventZoom.js';

export default function Home() {
    const [isMd, setIsMd] = useState(window.matchMedia('(min-width: 900px)').matches);
    const [showScrollToTop, setShowScrollToTop] = useState(false);
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        setProgress(30);
        setTimeout(() => {
            setProgress(100);
        }, 600);
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const mediaQuery = window.matchMedia('(min-width: 900px)');
        const updateIsMd = () => {
            setIsMd(mediaQuery.matches);
        };

        mediaQuery.addListener(updateIsMd);
        return () => {
            mediaQuery.removeListener(updateIsMd);
        };
    }, []);

    const handleScroll = () => {
        if (window.scrollY > 350) {
            setShowScrollToTop(true);
        } else {
            setShowScrollToTop(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const arrowTransition = useTransition(showScrollToTop, {
        from: { opacity: 0, transform: 'translateY(100%)' },
        enter: { opacity: 1, transform: 'translateY(0%)' },
        leave: { opacity: 0, transform: 'translateY(100%)' },
        config: { tension: 300, friction: 20 },
    });

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: false });
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    usePreventZoom();

    return (
        <div className="App">
            <Navbar />

            {/* SECTION PROFILE */}
            <div className='pt-20 min-w-screen'>
                {/* Loading Bar */}
                <LoadingBar color="#8C001f" progress={progress} onLoaderFinished={() => setProgress(0)} />
                <Profile></Profile>
                <div className='flex flex-col bg-white items-center relative'>
                    <News></News>
                </div>
                <div className='flex flex-col bg-lightGrey items-center relative'>
                    <CourseProject></CourseProject>
                </div>
                <div className='flex flex-col bg-white items-center relative'>
                    <Award></Award>
                </div>
                <div className='flex flex-col bg-white items-center relative'>
                    <External></External>
                </div>
            </div>
            <Footer />

            {arrowTransition((style, item) =>
                item ? (
                    <animated.div
                        style={style}
                        className="fixed bottom-5 right-5 cursor-pointer z-10"
                        onClick={scrollToTop}
                    >
                        <FaArrowCircleUp
                            size={isMd ? 32 : 24}
                            className="text-[#c0c5ca] hover:scale-110"
                        />
                    </animated.div>
                ) : null
            )}
        </div>
    );
}
