import React, { useEffect, useState } from 'react';
import FloodFillDoc from '../assets/FloodFill-Doc.pdf';
import BatikDoc from '../assets/BatikClassification-Doc.pdf';

const CourseProject: React.FC = () => {
    const [isMd, setIsMd] = useState(window.matchMedia('(min-width: 67.5rem)').matches);

    useEffect(() => {
        const mediaQuery = window.matchMedia('(min-width: 67.5rem)');
        const updateIsMd = () => {
            setIsMd(mediaQuery.matches);
        };
    
        mediaQuery.addListener(updateIsMd);
        return () => {
            mediaQuery.removeListener(updateIsMd);
        };
    }, []);

    return(
        <div className=' flex flex-col justify-start items-left md:items-center' >
            <br/>
                <p className='text-textGrey font-condensed text-center font-extrabold text-lg' id="project" >
                COURSE PROJECT
                </p>
                <br/>
                {isMd ?(
                <div>
                    <h2 className='text-textGrey font-condensed text-left text-2xl'>
                    2024
                    </h2>
                    <ul className="text-textGrey text-md text-left font-condensed font-extralight max-w-6xl" style={{paddingLeft: '2rem', marginRight: '17rem', listStyleType: 'disc' }}>
                        <li>
                            <span className='text-textRed'><b>LLM Project: Documents Summarization APP using LaMini LLM from MBZUAI</b></span> (<span className='text-textRed'><i>IF3270 - Machine Learning,</i> Feb 2024</span>)<br/>
                            Langchain, Streamlit, Transformers, Torch, Fastapi, Uvicorn <br/>
                            <a href='https://github.com/rifqifarhansyah/LaMiniLLMSummarization'>
                                <button className="bg-textRed border border-transparent text-white px-2 py-0.25 rounded mt-0.5 mb-2 text-sm hover:border-textRed hover:bg-lightGrey hover:text-textRed mr-2">Repository</button>
                            </a>
                        </li>
                        <li>
                            <span className='text-textRed'><b>Deep Learning Project: Potato Disease Classification</b></span> (<span className='text-textRed'><i>IF3270 - Machine Learning,</i> Feb 2024</span>)<br/>
                            React, FastAPI, Tensorflow, Uvicorn, PIL, numpy <br/>
                            <a href='https://github.com/rifqifarhansyah/PotatoDiseaseCNN'>
                                <button className="bg-textRed border border-transparent text-white px-2 py-0.25 rounded mt-0.5 mb-2 text-sm hover:border-textRed hover:bg-lightGrey hover:text-textRed mr-2">Repository</button>
                            </a>
                        </li>
                    </ul>
                    <h2 className='text-textGrey font-condensed text-left text-2xl mt-4'>
                    2023
                    </h2>
                    <ul className="text-textGrey text-md text-left font-condensed font-extralight w-[65.625rem] h-[52vh] overflow-y-scroll 2xl:h-auto 2xl:overflow-none list-disc pl-8">
                        <li>
                            <span className='text-textRed'><b>Ikomers: Online Shop Monolitic Website</b></span> (<span className='text-textRed'><i>IF3110 - Web Based Development,</i> Oct 2023</span>)<br/>
                            Php, HTML, CSS, JavaScript, Docker, MySQL<br/>
                            <a href='https://github.com/rifqifarhansyah/ECommerceWeb-Monolithic'>
                                <button className="bg-textRed border border-transparent text-white px-2 py-0.25 rounded mt-0.5 mb-2 text-sm hover:border-textRed hover:bg-lightGrey hover:text-textRed mr-2">Repository</button>
                            </a>
                        </li>
                        <li>
                            <span className='text-textRed'><b>ChatGPTLite</b></span> (<span className='text-textRed'><i>IF2211 - Algorithm Strategies,</i> May 2023</span>)<br/>
                            NextJS, React, Tailwind<br/>
                            <a href='https://github.com/rifqifarhansyah/ChatGPT-Lite'>
                                <button className="bg-textRed border border-transparent text-white px-2 py-0.25 rounded mt-0.5 mb-2 text-sm hover:border-textRed hover:bg-lightGrey hover:text-textRed mr-2">Repository</button>
                            </a>
                            <a href='https://chatgpt-lite-alpha.vercel.app/'>
                                <button className="bg-textRed border border-transparent text-white px-2 py-0.25 rounded mt-0.5 mb-2 text-sm hover:border-textRed hover:bg-lightGrey hover:text-textRed mr-2">Demo</button>
                            </a>
                        </li>
                        <li>
                            <span className='text-textRed'><b>Simple Paint: Flood Fill Application using BFS/DFS</b></span> (<span className='text-textRed'><i>IF2211 - Algorithm Strategies,</i> May 2023</span>)<br/>
                            Python, PyGame<br/>
                            <a href='https://github.com/rifqifarhansyah/FloodFill-SimplePaint'>
                                <button className="bg-textRed border border-transparent text-white px-2 py-0.25 rounded mt-0.5 mb-2 text-sm hover:border-textRed hover:bg-lightGrey hover:text-textRed mr-2">Repository</button>
                            </a>
                            <a href={FloodFillDoc}>
                                <button className="bg-textRed border border-transparent text-white px-2 py-0.25 rounded mt-0.5 mb-2 text-sm hover:border-textRed hover:bg-lightGrey hover:text-textRed mr-2">Paper</button>
                            </a>
                        </li>
                        <li>
                            <span className='text-textRed'><b>Shortest Path Finder Website with UCS and A* Algorithm</b></span> (<span className='text-textRed'><i>IF2211 - Algorithm Strategies,</i> April 2023</span>)<br/>
                            HTML, CSS, Javascript<br/>
                            <a href='https://github.com/rifqifarhansyah/PathFinder'>
                                <button className="bg-textRed border border-transparent text-white px-2 py-0.25 rounded mt-0.5 mb-2 text-sm hover:border-textRed hover:bg-lightGrey hover:text-textRed mr-2">Repository</button>
                            </a>
                            <a href='https://tucil-3-g7s9.vercel.app/'>
                                <button className="bg-textRed border border-transparent text-white px-2 py-0.25 rounded mt-0.5 mb-2 text-sm hover:border-textRed hover:bg-lightGrey hover:text-textRed mr-2">Demo</button>
                            </a>
                        </li>
                        <li>
                            <span className='text-textRed'><b>Poker Card Game</b></span> (<span className='text-textRed'><i>IF2210 - Object Oriented Programming,</i> April 2023</span>)<br/>
                            Make, C++<br/>
                            <a href='https://github.com/rifqifarhansyah/Tubes1_OOP'>
                                <button className="bg-textRed border border-transparent text-white px-2 py-0.25 rounded mt-0.5 mb-2 text-sm hover:border-textRed hover:bg-lightGrey hover:text-textRed mr-2">Repository</button>
                            </a>
                        </li>
                        <li>
                            <span className='text-textRed'><b>BFS and DFS Algorithms for Solving Maze Treasure Hunt Problems</b></span> (<span className='text-textRed'><i>IF2211 - Algorithm Strategies,</i> Mar 2023</span>)<br/>
                            C#, Visual Studio, .Net Framework<br/>
                            <a href='https://github.com/rifqifarhansyah/TreasureHunter'>
                                <button className="bg-textRed border border-transparent text-white px-2 py-0.25 rounded mt-0.5 mb-2 text-sm hover:border-textRed hover:bg-lightGrey hover:text-textRed mr-2">Repository</button>
                            </a>
                        </li>
                        <li>
                            <span className='text-textRed'><b>Closest Pair Problem Solver</b></span> (<span className='text-textRed'><i>IF2211 - Algorithm Strategies,</i> Feb 2023</span>)<br/>
                            python, matplotlib<br/>
                            <a href='https://github.com/rifqifarhansyah/Tucil2_13521166'>
                                <button className="bg-textRed border border-transparent text-white px-2 py-0.25 rounded mt-0.5 mb-2 text-sm hover:border-textRed hover:bg-lightGrey hover:text-textRed mr-2">Repository</button>
                            </a>
                        </li>
                        <li>
                            <span className='text-textRed'><b>24-Game Solver</b></span> (<span className='text-textRed'><i>IF2211 - Algorithm Strategies,</i> Feb 2023</span>)<br/>
                            python, matplotlib<br/>
                            <a href='https://github.com/rifqifarhansyah/24-Game-Solver'>
                                <button className="bg-textRed border border-transparent text-white px-2 py-0.25 rounded mt-0.5 mb-2 text-sm hover:border-textRed hover:bg-lightGrey hover:text-textRed mr-2">Repository</button>
                            </a>
                        </li>
                    </ul>
                    <h2 className='text-textGrey font-condensed text-left text-2xl mt-4'>
                    2022
                    </h2>
                    <ul className="text-textGrey text-md text-left font-condensed font-extralight max-w-6xl" style={{paddingLeft: '2rem', marginRight: '21rem', listStyleType: 'disc' }}>
                        <li>
                            <span className='text-textRed'><b>Batik Pattern Classification using Decision Tree</b></span> (<span className='text-textRed'><i>IF2120 - Discrete Mathematics,</i> Dec 2022</span>)<br/>
                            OpenCV2, numpy, sklearn, h5py, mahotas<br/>
                            <a href='https://github.com/rifqifarhansyah/Batik-Pattern-Classification'>
                                <button className="bg-textRed border border-transparent text-white px-2 py-0.25 rounded mt-0.5 mb-2 text-sm hover:border-textRed hover:bg-lightGrey hover:text-textRed mr-2">Repository</button>
                            </a>
                            <a href={BatikDoc}>
                                <button className="bg-textRed border border-transparent text-white px-2 py-0.25 rounded mt-0.5 mb-2 text-sm hover:border-textRed hover:bg-lightGrey hover:text-textRed mr-2">Paper</button>
                            </a>
                        </li>
                        <li>
                            <span className='text-textRed'><b>EigenFace Recognition in GUI</b></span> (<span className='text-textRed'><i>IF2123 - Linear Algebra and Geometry,</i> Nov 2022</span>)<br/>
                            customTkinter, OpenCV2, PIL, fpdf, numpy, sys<br/>
                            <a href='https://github.com/rifqifarhansyah/EigenFace-Recognition'>
                                <button className="bg-textRed border border-transparent text-white px-2 py-0.25 rounded mt-0.5 mb-2 text-sm hover:border-textRed hover:bg-lightGrey hover:text-textRed mr-2">Repository</button>
                            </a>
                        </li>
                        <li>
                            <span className='text-textRed'><b>Matrix Calculator in Java</b></span> (<span className='text-textRed'><i>IF2123 - Linear Algebra and Geometry,</i> Aug 2022</span>)<br/>
                            java, intellijIDEA<br/>
                            <a href='https://github.com/rifqifarhansyah/MatrixCalculator'>
                                <button className="bg-textRed border border-transparent text-white px-2 py-0.25 rounded mt-0.5 mb-2 text-sm hover:border-textRed hover:bg-lightGrey hover:text-textRed mr-2">Repository</button>
                            </a>
                        </li>
                    </ul>
                </div>
                ) : (
                <div className='md:w-[95vw] sm:w-[93vw] h-auto pr-2'>
                    <h2 className='text-textGrey font-condensed text-left text-2xl mx-10'>
                    2024
                    </h2>
                    <ul className="text-textGrey text-sm text-left font-condensed font-extralight pl-8 list-disc">
                        <li className='mx-10'>
                            <span className='text-textRed'><b>LLM Project: Documents Summarization APP using LaMini LLM from MBZUAI</b></span> (<span className='text-textRed'><i>IF3270 - Machine Learning,</i> Feb 2024</span>)<br/>
                            Langchain, Streamlit, Transformers, Torch, Fastapi, Uvicorn <br/>
                            <a href='https://github.com/rifqifarhansyah/LaMiniLLMSummarization'>
                                <button className="bg-textRed border border-transparent text-white px-2 py-0.25 rounded mt-0.5 mb-2 text-sm hover:border-textRed hover:bg-lightGrey hover:text-textRed mr-2">Repository</button>
                            </a>
                        </li>
                        <li className='mx-10'>
                            <span className='text-textRed'><b>Deep Learning Project: Potato Disease Classification</b></span> (<span className='text-textRed'><i>IF3270 - Machine Learning,</i> Feb 2024</span>)<br/>
                            React, FastAPI, Tensorflow, Uvicorn, PIL, numpy <br/>
                            <a href='https://github.com/rifqifarhansyah/PotatoDiseaseCNN'>
                                <button className="bg-textRed border border-transparent text-white px-2 py-0.25 rounded mt-0.5 mb-2 text-sm hover:border-textRed hover:bg-lightGrey hover:text-textRed mr-2">Repository</button>
                            </a>
                        </li>
                    </ul>
                    <br/>
                    <h2 className='text-textGrey font-condensed text-left text-2xl mx-10'>
                    2023
                    </h2>
                    <ul className="text-textGrey text-sm text-left font-condensed font-extralight h-[33vh] overflow-y-scroll pl-8 list-disc ">
                        <li className='mx-10'>
                            <span className='text-textRed'><b>Ikomers: Online Shop Monolitic Website</b></span> (<span className='text-textRed'><i>IF3110 - Web Based Development,</i> Oct 2023</span>)<br/>
                            Php, HTML, CSS, JavaScript, Docker, MySQL<br/>
                            <a href='https://github.com/rifqifarhansyah/ECommerceWeb-Monolithic'>
                                <button className="bg-textRed border border-transparent text-white px-2 py-0.25 rounded mt-0.5 mb-2 text-sm hover:border-textRed hover:bg-lightGrey hover:text-textRed mr-2">Repository</button>
                            </a>
                        </li>
                        <li className='mx-10'>
                            <span className='text-textRed'><b>ChatGPTLite</b></span> (<span className='text-textRed'><i>IF2211 - Algorithm Strategies,</i> May 2023</span>)<br/>
                            NextJS, React, Tailwind<br/>
                            <a href='https://github.com/rifqifarhansyah/ChatGPT-Lite'>
                                <button className="bg-textRed border border-transparent text-white px-2 py-0.25 rounded mt-0.5 mb-2 text-sm hover:border-textRed hover:bg-lightGrey hover:text-textRed mr-2">Repository</button>
                            </a>
                            <a href='https://chatgpt-lite-alpha.vercel.app/'>
                                <button className="bg-textRed border border-transparent text-white px-2 py-0.25 rounded mt-0.5 mb-2 text-sm hover:border-textRed hover:bg-lightGrey hover:text-textRed mr-2">Demo</button>
                            </a>
                        </li>
                        <li className='mx-10'>
                            <span className='text-textRed'><b>Simple Paint: Flood Fill Application using BFS/DFS</b></span> (<span className='text-textRed'><i>IF2211 - Algorithm Strategies,</i> May 2023</span>)<br/>
                            Python, PyGame<br/>
                            <a href='https://github.com/rifqifarhansyah/FloodFill-SimplePaint'>
                                <button className="bg-textRed border border-transparent text-white px-2 py-0.25 rounded mt-0.5 mb-2 text-sm hover:border-textRed hover:bg-lightGrey hover:text-textRed mr-2">Repository</button>
                            </a>
                            <a href={FloodFillDoc}>
                                <button className="bg-textRed border border-transparent text-white px-2 py-0.25 rounded mt-0.5 mb-2 text-sm hover:border-textRed hover:bg-lightGrey hover:text-textRed mr-2">Paper</button>
                            </a>
                        </li>
                        <li className='mx-10'>
                            <span className='text-textRed'><b>Shortest Path Finder Website with UCS and A* Algorithm</b></span> (<span className='text-textRed'><i>IF2211 - Algorithm Strategies,</i> April 2023</span>)<br/>
                            HTML, CSS, Javascript<br/>
                            <a href='https://github.com/rifqifarhansyah/PathFinder'>
                                <button className="bg-textRed border border-transparent text-white px-2 py-0.25 rounded mt-0.5 mb-2 text-sm hover:border-textRed hover:bg-lightGrey hover:text-textRed mr-2">Repository</button>
                            </a>
                            <a href='https://tucil-3-g7s9.vercel.app/'>
                                <button className="bg-textRed border border-transparent text-white px-2 py-0.25 rounded mt-0.5 mb-2 text-sm hover:border-textRed hover:bg-lightGrey hover:text-textRed mr-2">Demo</button>
                            </a>
                        </li>
                        <li className='mx-10'>
                            <span className='text-textRed'><b>Poker Card Game</b></span> (<span className='text-textRed'><i>IF2210 - Object Oriented Programming,</i> April 2023</span>)<br/>
                            Make, C++<br/>
                            <a href='https://github.com/rifqifarhansyah/Tubes1_OOP'>
                                <button className="bg-textRed border border-transparent text-white px-2 py-0.25 rounded mt-0.5 mb-2 text-sm hover:border-textRed hover:bg-lightGrey hover:text-textRed mr-2">Repository</button>
                            </a>
                        </li>
                        <li className='mx-10'>
                            <span className='text-textRed'><b>BFS and DFS Algorithms for Solving Maze Treasure Hunt Problems</b></span> (<span className='text-textRed'><i>IF2211 - Algorithm Strategies,</i> Mar 2023</span>)<br/>
                            C#, Visual Studio, .Net Framework<br/>
                            <a href='https://github.com/rifqifarhansyah/TreasureHunter'>
                                <button className="bg-textRed border border-transparent text-white px-2 py-0.25 rounded mt-0.5 mb-2 text-sm hover:border-textRed hover:bg-lightGrey hover:text-textRed mr-2">Repository</button>
                            </a>
                        </li>
                        <li className='mx-10'>
                            <span className='text-textRed'><b>Closest Pair Problem Solver</b></span> (<span className='text-textRed'><i>IF2211 - Algorithm Strategies,</i> Feb 2023</span>)<br/>
                            python, matplotlib<br/>
                            <a href='https://github.com/rifqifarhansyah/Tucil2_13521166'>
                                <button className="bg-textRed border border-transparent text-white px-2 py-0.25 rounded mt-0.5 mb-2 text-sm hover:border-textRed hover:bg-lightGrey hover:text-textRed mr-2">Repository</button>
                            </a>
                        </li>
                        <li className='mx-10'>
                            <span className='text-textRed'><b>24-Game Solver</b></span> (<span className='text-textRed'><i>IF2211 - Algorithm Strategies,</i> Feb 2023</span>)<br/>
                            python, matplotlib<br/>
                            <a href='https://github.com/rifqifarhansyah/24-Game-Solver'>
                                <button className="bg-textRed border border-transparent text-white px-2 py-0.25 rounded mt-0.5 mb-2 text-sm hover:border-textRed hover:bg-lightGrey hover:text-textRed mr-2">Repository</button>
                            </a>
                        </li>
                    </ul>
                    <br/>
                    <h2 className='text-textGrey font-condensed text-left text-2xl mx-10'>
                    2022
                    </h2>
                    <ul className="text-textGrey text-sm text-left font-condensed font-extralight max-w-6xl" style={{paddingLeft: '2rem', listStyleType: 'disc' }}>
                        <li className='mx-10'>
                            <span className='text-textRed'><b>Batik Pattern Classification using Decision Tree</b></span> (<span className='text-textRed'><i>IF2120 - Discrete Mathematics,</i> Dec 2022</span>)<br/>
                            OpenCV2, numpy, sklearn, h5py, mahotas<br/>
                            <a href='https://github.com/rifqifarhansyah/Batik-Pattern-Classification'>
                                <button className="bg-textRed border border-transparent text-white px-2 py-0.25 rounded mt-0.5 mb-2 text-sm hover:border-textRed hover:bg-lightGrey hover:text-textRed mr-2">Repository</button>
                            </a>
                            <a href={BatikDoc}>
                                <button className="bg-textRed border border-transparent text-white px-2 py-0.25 rounded mt-0.5 mb-2 text-sm hover:border-textRed hover:bg-lightGrey hover:text-textRed mr-2">Paper</button>
                            </a>
                        </li>
                        <li className='mx-10'>
                            <span className='text-textRed'><b>EigenFace Recognition in GUI</b></span> (<span className='text-textRed'><i>IF2123 - Linear Algebra and Geometry,</i> Nov 2022</span>)<br/>
                            customTkinter, OpenCV2, PIL, fpdf, numpy, sys<br/>
                            <a href='https://github.com/rifqifarhansyah/EigenFace-Recognition'>
                                <button className="bg-textRed border border-transparent text-white px-2 py-0.25 rounded mt-0.5 mb-2 text-sm hover:border-textRed hover:bg-lightGrey hover:text-textRed mr-2">Repository</button>
                            </a>
                        </li>
                        <li className='mx-10'>
                            <span className='text-textRed'><b>Matrix Calculator in Java</b></span> (<span className='text-textRed'><i>IF2123 - Linear Algebra and Geometry,</i> Aug 2022</span>)<br/>
                            java, intellijIDEA<br/>
                            <a href='https://github.com/rifqifarhansyah/MatrixCalculator'>
                                <button className="bg-textRed border border-transparent text-white px-2 py-0.25 rounded mt-0.5 mb-2 text-sm hover:border-textRed hover:bg-lightGrey hover:text-textRed mr-2">Repository</button>
                            </a>
                        </li>
                    </ul>
                </div>
                )}
            <br/>
        </div>
    )
};

export default CourseProject
