import React, { useEffect, useState } from 'react';
import LoadingBar from 'react-top-loading-bar';
import { useTransition, animated } from 'react-spring';
import { FaArrowCircleUp, FaAngleDoubleLeft, FaAngleDoubleRight, FaEye } from 'react-icons/fa';
import NavbarDetailBlog from '../../../components/navbarDetailBlog.tsx';
import HeaderDetailBlog from '../utils/headerDetailBlog.tsx';
import Footer from '../../../components/footer.tsx';
import { Link } from 'react-router-dom';
import ImageModal from '../utils/imageModal.tsx';
import CommentListExample from '../../../components/comments/commentListExample.tsx';


import usePreventZoom from '../../../preventZoom.js';

import Header from '../../../assets/Blog/SAFMC/SAFMC_PART2/Detail/HEADER_DETAIL_SAFMC_PART2.webp';
import Pict1 from "../../../assets/Blog/SAFMC/SAFMC_PART2/Detail/1.webp";
import Pict2 from "../../../assets/Blog/SAFMC/SAFMC_PART2/Detail/2.webp";
import Pict3 from "../../../assets/Blog/SAFMC/SAFMC_PART2/Detail/3.webp";
import Pict4 from "../../../assets/Blog/SAFMC/SAFMC_PART2/Detail/4.webp";
import Pict5 from "../../../assets/Blog/SAFMC/SAFMC_PART2/Detail/5.webp";
import Pict6 from "../../../assets/Blog/SAFMC/SAFMC_PART2/Detail/6.webp";
import Pict7 from "../../../assets/Blog/SAFMC/SAFMC_PART2/Detail/7.webp";
import Pict8 from "../../../assets/Blog/SAFMC/SAFMC_PART2/Detail/8.webp";

import Gallery1 from "../../../assets/Blog/SAFMC/SAFMC_PART2/Gallery/1.webp";
import Gallery2 from "../../../assets/Blog/SAFMC/SAFMC_PART2/Gallery/2.webp";
import Gallery3 from "../../../assets/Blog/SAFMC/SAFMC_PART2/Gallery/3.webp";
import Gallery4 from "../../../assets/Blog/SAFMC/SAFMC_PART2/Gallery/4.webp";
import Gallery5 from "../../../assets/Blog/SAFMC/SAFMC_PART2/Gallery/5.webp";
import Gallery6 from "../../../assets/Blog/SAFMC/SAFMC_PART2/Gallery/6.webp";
import Gallery7 from "../../../assets/Blog/SAFMC/SAFMC_PART2/Gallery/7.webp";
import Gallery8 from "../../../assets/Blog/SAFMC/SAFMC_PART2/Gallery/8.webp";
import Gallery9 from "../../../assets/Blog/SAFMC/SAFMC_PART2/Gallery/9.webp";
import Gallery10 from "../../../assets/Blog/SAFMC/SAFMC_PART2/Gallery/10.webp";
import Gallery11 from "../../../assets/Blog/SAFMC/SAFMC_PART2/Gallery/11.webp";
import Gallery12 from "../../../assets/Blog/SAFMC/SAFMC_PART2/Gallery/12.webp";

const SAFMCPART2: React.FC = () => {
    const [isMd, setIsMd] = useState(window.matchMedia('(min-width: 900px)').matches);
    const [showScrollToTop, setShowScrollToTop] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
    const [selectedAlt, setSelectedAlt] = useState('');
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        setProgress(30);
        setTimeout(() => {
            setProgress(100);
        }, 600);
    }, []);
  
    const openModal = (imageUrl, imageAlt) => {
        setSelectedImage(imageUrl);
        setSelectedAlt(imageAlt);
        setModalVisible(true);
    };
  
    const closeModal = () => {
        setSelectedImage('');
        setSelectedAlt('');
        setModalVisible(false);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const mediaQuery = window.matchMedia('(min-width: 900px)');
        const updateIsMd = () => {
            setIsMd(mediaQuery.matches);
        };

        mediaQuery.addListener(updateIsMd);
        return () => {
            mediaQuery.removeListener(updateIsMd);
        };
    }, []);

    const handleScroll = () => {
        if (window.scrollY > 350) {
            setShowScrollToTop(true);
        } else {
            setShowScrollToTop(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const arrowTransition = useTransition(showScrollToTop, {
        from: { opacity: 0, transform: 'translateY(100%)' },
        enter: { opacity: 1, transform: 'translateY(0%)' },
        leave: { opacity: 0, transform: 'translateY(100%)' },
        config: { tension: 300, friction: 20 },
      });

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, {passive: false});
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    usePreventZoom();

    return(
        <div className='App'>
            <NavbarDetailBlog/>
            <div className='pt-20 min-w-screen font-condensed text-textGrey'>
                {/* Loading Bar */}
                <LoadingBar color="#8C001f" progress={progress} onLoaderFinished={() => setProgress(0)} />
                <section className='hidden w-64 mr-10 group transform hover:translate-y-[-5px] transition-transform duration-300 ease-in-out'>
                        <script type='text/javascript' id='clustrmaps' src='//cdn.clustrmaps.com/map_v2.js?cl=e1e1e1&w=a&t=tt&d=mIBEHA96Sa9wc29y0yefyCb9wP8YgephePOwDaQRDk0&co=ffffff&cmo=8c001f&cmn=d4201e&ct=394048'></script>
                </section>
                <HeaderDetailBlog backgroundImage={Header} title="My First Departure Abroad" date="23" year="December, 2023" backgroundPosition="center bottom"></HeaderDetailBlog>
                <div className='author-article text-end pr-4 pt-8 pb-4 text-xs'>
                    <p className='font-condensed text-textGrey'>by Mohammad Rifqi Farhansyah | Singapore Amazing Flying Machine Competition 2023</p>
                </div>
                <div className='article-content-1 md:flex md:flex-row flex flex-col md:px-10 px-10 gap-x-4 gap-y-4 md:mb-16 mb-8'>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Pict1, 'I was standing in the middle, while two of my friends, M. Dimas Sakti W., were on the left-hand side, and Nuraini Khadijah Sekar S. was on the right-hand side')}>
                            <img
                                src={Pict1}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="I was standing in the middle, while two of my friends, M. Dimas Sakti W., were on the left-hand side, and Nuraini Khadijah Sekar S. was on the right-hand side"
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc w-full h-auto'>
                            <p className='text-lg font-condensed text-start'><i>I was standing in the middle, while two of my friends, M. Dimas Sakti W., were on the left-hand side, and Nuraini Khadijah Sekar S. was on the right-hand side</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi | Kak Dija's House</i></p>
                        </div>
                    </div>
                    <div className='article-content__text md:w-2/3 w-full h-auto text-justify'>
                        <p className='text-2xl'>After long preparation, from registering my self to join ITB's SAFMC team 2023, learn a lot of new thing, and preparing departure, it was the showtime. I would go to Singapore (for the first time), joining Singapore Amazing Flying Machine Competition 2023 with 1000 others student from various countries.</p>
                        <br/>
                        <p className='text-lg'>On March 26, 2023, at 13:37 WIB, I was joined in the 2nd departure's group. The first group flied earlier cause they will joined the C1 and C2 Category. The 2nd group was consisting of me, my friend Sakti as a programmer like me and Kak Dija as a manager who will prepared all of our needed while we were in Singapore. All of the stuff related to procurement and non-technical stuff will be covered by her. Btw, thank you for BAKTI BARITO foundation that have been sponsored us for this competition.</p>
                        <br/>
                        <p className='text-lg'>We will flight at 16:00 WIB, thats why we need to go to Husein Sastranegara Airport at least 1 hour before our departure. After ensuring that we have brought all the necessary competition equipment, we then departed for Husein Sastranegara Airport in Bandung, our departure to Husein Sastranegara Airport was accompanied by Kak Dija's parents. Instead of flying directly to Changi Airport, Singapore, we will first head to Hang Nadhim Airport in Batam because some of the prepared items are in transit there. From Batam, we will take a ferry to proceed to Singapore.</p>
                    </div>
                </div>
                <div className='article-content-1 md:flex md:flex-row flex flex-col md:px-10 px-10 gap-x-4 gap-y-4 md:mb-36 mb-20'>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Pict2, 'We have arrived at Husein Sastranegara Airport with the same photo`s position as before')}>
                            <img
                                src={Pict2}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="We have arrived at Husein Sastranegara Airport with the same position as before"
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto'>
                            <p className='text-lg font-condensed text-start'><i>We have arrived at Husein Sastranegara Airport with the same photo's position as before</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi | International Terminal, Husein Sastranegara Airport</i></p>
                        </div>
                    </div>
                    <div className='article-content__text md:w-2/3 w-full h-auto text-justify'>
                        <p className='text-lg'>We finally arrived at the international terminal of Husein Sastranegara Airport at 14:51 WIB. Upon our arrival, Kak Dija's sister assisted us in completing the check-in process for our flight. Firstly, we printed the tickets that we had reserved through an online application. Subsequently, we checked in and weighed our carry-on baggage. We were not overly concerned about exceeding the maximum weight limits for cabin and checked baggage since we had weighed our bags and suitcases at Kak Dija's home. As anticipated, our belongings did not exceed the allowable weight. With everything sorted, we prepared for our flight.</p>
                        <br/>
                        <p className='text-lg'>I've flown by the plane before, but this was my friend Sakti's first experience flying. His face showed nervousness as we walked towards the plane, especially since it was drizzling, and the sky was filled with dark clouds. I tried to crack a few jokes with Sakti, but it seemed ineffective. He still had a fearful look even after the three of us were seated inside the plane. The interior of the Super Air Jet was quite nice and comfortable, although there was no TV at the back of the seats in front of us, unlike other planes I've been on. Before takeoff, we took some time to take photos, capturing Sakti's anxious expression. However, he was clever at hiding his fear when posing for pictures. A few minutes later, the pilot announced that we would soon be taking off. We tightened our seatbelts and said a prayer. Finally, our plane took off.</p>
                    </div>
                </div>
                <div className='article-break-1 flex justify-center items-center flex-col md:mb-36 mb-20'>
                    <hr className='w-5/6 mt-4 md:mb-12 mb-4  border-t border-b border-solid'/>
                    <p className='md:text-5xl text-2xl font-bold text-black md:px-44 px-10'>"I hope our drones can fly as well as this plane."</p>
                    <hr className='w-5/6 mb-4 md:mt-12 mt-4 border-t border-b border-solid'/>
                </div>
                <div className='bg-fixed bg-center w-full h-96 md:mb-36 mb-20' style={{ backgroundImage: `url(${Pict3})`, backgroundSize: 'cover', }}>
                </div>
                <div className='article-content-1 md:flex md:flex-row flex flex-col md:px-10 px-10 gap-x-4 gap-y-4 md:mb-16 mb-8'>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Pict4, 'The sky is beautiful, isn`t it?')}>
                            <img
                                src={Pict4}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="The sky is beautiful, isn`t it?"
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto'>
                            <p className='text-lg font-condensed text-start'><i>The sky is beautiful, isn't it?</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                    <div className='article-content__text md:w-2/3 w-full h-auto text-justify'>
                        <p className='text-lg'>Sakti still appeared nervous, especially during takeoff, and I understand that because such conditions can be a bit uncomfortable. Our plane experienced turbulence several times, possibly due to the less-than-ideal weather conditions. However, a few minutes later, the bad weather began to clear, and the sky became quite bright. The sun even started to shine into our plane through the window next to where Kak Dija was sitting. This journey felt quite long, perhaps because of my impatience to set foot in Singapore as soon as possible. </p>
                        <br/>
                        <p className='text-lg'>While inside the plane, I kept looking at my phone and the scenery outside the window. It just dawned on me that this was my first inter-island flight. Through the plane window, I could see the beauty of the beaches along the coasts of Java and Sumatra, which were truly exotic. How fortunate are we as Indonesian citizens?</p>
                        <br/>
                        <p className='text-lg'>Just so you know, this journey was undertaken during the month of fasting, so we didn't receive any snacks or drinks from the airline :((((. Consequently, the journey felt very long and quite tiring. We couldn't get the usual bread and mineral water provided by certain airlines for their passengers. However, I just realized now that the experience during Ramadan is actually very interesting and quite memorable for me. As a Muslim, we usually ask to be respected because we are fasting. However, the essence of fasting is actually to be able to appreciate others.</p>
                        <br/>
                    </div>
                </div>
                <div className='article-content-1 md:flex md:flex-row flex flex-col md:px-10 px-10 gap-x-4 gap-y-4 md:mb-20 mb-8'>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery7, 'Super Air Jet`s Interior Design')}>
                            <img
                                src={Gallery7}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="Super Air Jet`s Interior Design"
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>Super Air Jet's Interior Design</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery8, 'Try to crack some jokes with Sakti')}>
                            <img
                                src={Gallery8}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="Try to crack some jokes with Sakti"
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>Try to crack some jokes with Sakti</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery9, 'THEEE VIEWWW 😍')}>
                            <img
                                src={Gallery9}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="THEEE VIEWWW 😍"
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>THEEE VIEWWW </i>😍</p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                </div>
                <div className='article-break-1 flex justify-center items-center flex-col md:mb-36 mb-20'>
                    <hr className='w-5/6 mt-4 md:mb-12 mb-4  border-t border-b border-solid'/>
                    <p className='md:text-5xl text-2xl font-bold text-black md:px-44 px-10'>"My best Ramadan so far! :) I am very grateful to have had this experience."</p>
                    <hr className='w-5/6 mb-4 md:mt-12 mt-4 border-t border-b border-solid'/>
                </div>
                <div className='article-content-1 md:flex md:flex-row flex flex-col md:px-10 px-10 gap-x-4 gap-y-4 md:mb-10 mb-8'>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Pict5, 'Finally touch down at Hang Nadim Airport')}>
                            <img
                                src={Pict5}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="Finally touch down at Hang Nadim Airport"
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>Finally touch down at Hang Nadim Airport</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi | Hang Nadim Airport</i></p>
                        </div>
                    </div>
                    <div className='article-content__text md:w-2/3 w-full h-auto text-justify'>
                        <p className='text-lg'><i>At 17.30 WIB,</i> after 1.5 hours of flight from Husein Sastranegara Airport (BDO), we finally touch down at Hang Nadim Airport (BTH). I feel very happy because this is the first time I've set foot outside of Java. After getting off the plane, we took some photos before finally boarding the bus that would take us to the international arrival terminal. There, our teammates from group 1, Farel and Yoga, were waiting to pick us up. They also brought some snacks and drinks for us to break our fast later. We then got into the pickup car, and not long after, we entered the Maghrib time. Together, we enjoyed the food and drinks prepared by Yoga and Farel, including sausages, dates, bread, and water.</p>
                        <br/>
                        <p className='text-lg'>From Hang Nadim Airport, we headed to our team leader Kak Zeke's house to pick up some items left there and to meet with Kak Zeke before departing together to the port. We had to hurry because the time was already 18:20 WIB, and the last ship to Harbour Front was scheduled to depart at 19:30 WIB. Fortunately, we managed to reach the port on time. We even had time to buy some food to satisfy our hunger after fasting all day. While eating, we suddenly heard a call for passengers of the last Majestic ship departing to Harbour Front. Finally, we rushed to board the ship, and the journey to Singapore began.</p>
                    </div>
                </div>
                <div className='article-content-1 md:flex md:flex-row flex flex-col md:px-10 px-10 gap-x-4 gap-y-4 md:mb-20 mb-8'>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Pict6, 'Our drone was spotted on Ferry')}>
                            <img
                                src={Pict6}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="Our drone was spotted on Ferry"
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>Our drone was spotted on Ferry</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi | Majestic Fast Ferry</i></p>
                        </div>
                    </div>
                    <div className='article-content__text md:w-2/3 w-full h-auto text-justify'>
                        <p className='text-lg'>Beyond my expectations, the rocking motion inside the ship turned out to be even more significant than the turbulence experienced on the plane. Several times, I felt like I was about to vomit, especially as we still had to finish the food we bought at the port while enduring the journey on the ship. While we were eating, Kak Zeke distributed pocket money for us for the next 2 days, which amounted to 100 SGD. The amount seemed too much for me :))). In fact, all of this felt a bit too luxurious for me.</p>
                        <br/>
                        <p className='text-lg'>In the middle of the journey, Kak Dija reminded us to purchase roaming internet data for use in Singapore later. After buying the internet data, I returned my attention to the sea we were crossing throughout the journey. At first, only the vast expanse of the ocean was visible, but as we continued, tall buildings with incredibly luxurious lighting started to appear at the end of our journey. Finally, we arrived at Harbour Front, Singapore. We had to go through immigration before being able to enter Singapore's territory. After completing the process, we finally entered Singapore.</p>
                    </div>
                </div>
                <div className='article-content-1 md:flex md:flex-row flex flex-col md:px-10 px-10 gap-x-4 gap-y-4 md:mb-20 mb-8'>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery1, 'On the Hang Nadim Airport`s Bus')}>
                            <img
                                src={Gallery1}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="On the Hang Nadim Airport`s Bus"
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>On the Hang Nadim Airport's Bus</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery2, 'Batam`s Vibes')}>
                            <img
                                src={Gallery2}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="Batam`s Vibes"
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i><s>San Francisco's</s> Batam's Vibes</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery3, 'At the Zeke Hotel')}>
                            <img
                                src={Gallery3}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="At the Zeke Hotel"
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>At the Zeke Hotel</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                </div>
                <div className='article-content-1 md:flex md:flex-row flex flex-col md:px-10 px-10 gap-x-4 gap-y-4 md:mb-20 mb-8'>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery10, 'Hang Nadim Airport (BTH)')}>
                            <img
                                src={Gallery10}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="Hang Nadim Airport (BTH)"
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>Hang Nadim Airport (BTH)</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery11, 'Ferry`s Interior')}>
                            <img
                                src={Gallery11}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="Ferry`s Interior"
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>Ferry's Interior</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery12, 'Need to spend this money just in 2 days :)')}>
                            <img
                                src={Gallery12}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="Need to spend this money just in 2 days :)"
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>Need to spend this money just in 2 days :)</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                </div>
                <div className='article-content-1 md:flex md:flex-row flex flex-col md:px-10 px-10 gap-x-4 gap-y-4 md:mb-36 mb-20'>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery4, 'Enjoying dinner at port')}>
                            <img
                                src={Gallery4}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="Enjoying dinner at port"
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>Enjoying dinner at port <s>5432</s></i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery5, 'Singapore MRT Station')}>
                            <img
                                src={Gallery5}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="Singapore MRT Station"
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>Singapore MRT Station</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery6, 'View at Our Hotel in Little India, Singapore')}>
                            <img
                                src={Gallery6}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="View at Our Hotel in Little India, Singapore"
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>View at Our Hotel in Little India, Singapore</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                </div>
                <div className='bg-fixed bg-center w-full h-96 md:mb-36 mb-20' style={{ backgroundImage: `url(${Pict7})`, backgroundSize: 'cover',  }}>
                </div>
                <div className='article-content-1 md:flex md:flex-row flex flex-col md:px-10 px-10 gap-x-4 gap-y-4 md:mb-36 mb-20'>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Pict8, 'Me and the boys on MRT')}>
                            <img
                                src={Pict8}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="Me and the boys on MRT"
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>Me and the boys on MRT</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                    <div className='article-content__text md:w-2/3 w-full h-auto text-justify'>
                        <p className='text-lg'>Upon arriving in Singapore, we immediately headed to the hotel we would be staying at in the Little India area, using the MRT. Although our bodies felt very tired, we had to work hard tonight with the goal of preparing for the competition tomorrow. I, Sakti, and Kak Zeke shared one room, while Farel and Yoga were in another room, and Kak Dija had a separate room as well. My room was designated as the preparation area for all the necessities for tomorrow's competition. Tomorrow's competition is in the D1 – Semi Autonomous Drone category, and the details of the competition will be explained further in the next article.</p>
                        <div className='article-break-1 flex justify-start items-center flex-col'>
                            <hr className='w-full mt-4 mb-4  border-t border-b border-solid'/>
                        </div>
                        <p className='text-lg font-bold'><i>to be continued...</i></p>
                    </div>
                </div>
                <div className='article-break-1 flex justify-center items-center flex-col md:mb-8 mb-4'>
                    <hr className='w-5/6 mt-4 md:mb-12 mb-4  border-t border-b border-solid'/>
                    <p className='md:text-5xl text-2xl font-bold text-black md:px-44 px-10'>"That night seemed to be longer than other night. I could felt all of the pressure."</p>
                    <hr className='w-5/6 mb-4 md:mt-12 mt-4 border-t border-b border-solid'/>
                </div>
            </div>
            <div className='flex justify-center items-center px-12 md:mb-8 mb-4 gap-x-8'>
                <Link to="/safmc/safmc-part1" className='md:text-xl text-lg text-textRed font-bold flex items-center hover:scale-105'>
                    <FaAngleDoubleLeft className='w-3 h-3 text-rgba-28-5-179 inline-block mr-1' />
                    PREV 
                </Link>
                <Link to="/safmc/safmc-part3" className='md:text-xl text-lg text-textRed font-bold flex items-center hover:scale-105'>
                    NEXT 
                    <FaAngleDoubleRight className='w-3 h-3 text-rgba-28-5-179 inline-block ml-1' />
                </Link>
            </div>
            <div className='bg-fixed w-full h-96' style={{ backgroundImage: `url(${Gallery9})`, backgroundSize: 'cover',  }}>
            </div>
            <CommentListExample postId={2}/>
            <ImageModal
                showModal={modalVisible}
                closeModal={closeModal}
                imageUrl={selectedImage}
                imageAlt={selectedAlt}
            />
            <Footer/>
            {arrowTransition((style, item) =>
                item ? (
                <animated.div
                    style={style}
                    className="fixed bottom-5 right-5 cursor-pointer z-10"
                    onClick={scrollToTop}
                >
                    <FaArrowCircleUp
                    size={isMd ? 32 : 24}
                    className="text-[#c0c5ca] hover:scale-110"
                    />
                </animated.div>
                ) : null
            )}
        </div>
    );
};

export default SAFMCPART2