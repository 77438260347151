import React, { useEffect, useState } from 'react';
import LoadingBar from 'react-top-loading-bar';
import { useTransition, animated } from 'react-spring';
import { FaArrowCircleUp, FaAngleDoubleLeft, FaAngleDoubleRight, FaEye } from 'react-icons/fa';
import NavbarDetailBlog from '../../../components/navbarDetailBlog.tsx';
import HeaderDetailBlog from '../utils/headerDetailBlog.tsx';
import Footer from '../../../components/footer.tsx';
import { Link } from 'react-router-dom';
import ImageModal from '../utils/imageModal.tsx';
import CommentListExample from '../../../components/comments/commentListExample.tsx';


import usePreventZoom from '../../../preventZoom.js';

import Header from '../../../assets/Blog/SAFMC/SAFMC_PART7/Detail/HEADER_DETAIL_SAFMC_PART7.webp';
import Pict1 from "../../../assets/Blog/SAFMC/SAFMC_PART7/Detail/1.webp";
import Pict2 from "../../../assets/Blog/SAFMC/SAFMC_PART7/Detail/2.webp";
import Pict3 from "../../../assets/Blog/SAFMC/SAFMC_PART7/Detail/3.webp";
import Pict4 from "../../../assets/Blog/SAFMC/SAFMC_PART7/Detail/4.webp";
import Pict5 from "../../../assets/Blog/SAFMC/SAFMC_PART7/Detail/5.webp";
import SertifD1 from "../../../assets/Blog/SAFMC/SAFMC_PART7/Detail/SertifikatSAFMCD1.webp";
import SertifD2 from "../../../assets/Blog/SAFMC/SAFMC_PART7/Detail/SertifikatSAFMCD2.webp";

import Gallery1 from "../../../assets/Blog/SAFMC/SAFMC_PART7/Gallery/1.webp";
import Gallery2 from "../../../assets/Blog/SAFMC/SAFMC_PART7/Gallery/2.webp";
import Gallery3 from "../../../assets/Blog/SAFMC/SAFMC_PART7/Gallery/3.webp";
import Gallery4 from "../../../assets/Blog/SAFMC/SAFMC_PART7/Gallery/4.webp";
import Gallery5 from "../../../assets/Blog/SAFMC/SAFMC_PART7/Gallery/5.webp";
import Gallery6 from "../../../assets/Blog/SAFMC/SAFMC_PART7/Gallery/6.webp";
import Gallery7 from "../../../assets/Blog/SAFMC/SAFMC_PART7/Gallery/7.webp";
import Gallery8 from "../../../assets/Blog/SAFMC/SAFMC_PART7/Gallery/8.webp";
import Gallery9 from "../../../assets/Blog/SAFMC/SAFMC_PART7/Gallery/9.webp";
import Gallery10 from "../../../assets/Blog/SAFMC/SAFMC_PART7/Gallery/10.webp";
import Gallery11 from "../../../assets/Blog/SAFMC/SAFMC_PART7/Gallery/11.webp";
import Gallery12 from "../../../assets/Blog/SAFMC/SAFMC_PART7/Gallery/12.webp";
import Gallery13 from "../../../assets/Blog/SAFMC/SAFMC_PART7/Gallery/13.webp";
import Gallery14 from "../../../assets/Blog/SAFMC/SAFMC_PART7/Gallery/14.webp";
import Gallery15 from "../../../assets/Blog/SAFMC/SAFMC_PART7/Gallery/15.webp";
import Gallery16 from "../../../assets/Blog/SAFMC/SAFMC_PART7/Gallery/16.webp";
import Gallery17 from "../../../assets/Blog/SAFMC/SAFMC_PART7/Gallery/17.webp";
import Gallery18 from "../../../assets/Blog/SAFMC/SAFMC_PART7/Gallery/18.webp";

const SAFMCPART7: React.FC = () => {
    const [isMd, setIsMd] = useState(window.matchMedia('(min-width: 900px)').matches);
    const [showScrollToTop, setShowScrollToTop] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
    const [selectedAlt, setSelectedAlt] = useState('');
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        setProgress(30);
        setTimeout(() => {
            setProgress(100);
        }, 600);
    }, []);
  
    const openModal = (imageUrl, imageAlt) => {
        setSelectedImage(imageUrl);
        setSelectedAlt(imageAlt);
        setModalVisible(true);
    };
  
    const closeModal = () => {
        setSelectedImage('');
        setSelectedAlt('');
        setModalVisible(false);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const mediaQuery = window.matchMedia('(min-width: 900px)');
        const updateIsMd = () => {
            setIsMd(mediaQuery.matches);
        };

        mediaQuery.addListener(updateIsMd);
        return () => {
            mediaQuery.removeListener(updateIsMd);
        };
    }, []);

    const handleScroll = () => {
        if (window.scrollY > 350) {
            setShowScrollToTop(true);
        } else {
            setShowScrollToTop(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const arrowTransition = useTransition(showScrollToTop, {
        from: { opacity: 0, transform: 'translateY(100%)' },
        enter: { opacity: 1, transform: 'translateY(0%)' },
        leave: { opacity: 0, transform: 'translateY(100%)' },
        config: { tension: 300, friction: 20 },
      });

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, {passive: false});
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    usePreventZoom();

    return(
        <div className='App'>
            <NavbarDetailBlog/>
            <div className='pt-20 min-w-screen font-condensed text-textGrey'>
                {/* Loading Bar */}
                <LoadingBar color="#8C001f" progress={progress} onLoaderFinished={() => setProgress(0)} />
                <section className='hidden w-64 mr-10 group transform hover:translate-y-[-5px] transition-transform duration-300 ease-in-out'>
                        <script type='text/javascript' id='clustrmaps' src='//cdn.clustrmaps.com/map_v2.js?cl=e1e1e1&w=a&t=tt&d=mIBEHA96Sa9wc29y0yefyCb9wP8YgephePOwDaQRDk0&co=ffffff&cmo=8c001f&cmn=d4201e&ct=394048'></script>
                </section>
                <HeaderDetailBlog backgroundImage={Header} title="Awarding Ceremony, 'Thanks God' " date="24" year="December, 2023" backgroundPosition="center center"></HeaderDetailBlog>
                <div className='author-article text-end pr-4 pt-8 pb-4 text-xs'>
                    <p className='font-condensed text-textGrey'>by Mohammad Rifqi Farhansyah | Singapore Amazing Flying Machine Competition 2023</p>
                </div>
                <div className='article-content-1 md:flex md:flex-row flex flex-col md:px-10 px-10 gap-x-4 gap-y-4 md:mb-16 mb-8'>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Pict1, "Our last sahur in Batam.")}>
                            <img
                                src={Pict1}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="Our last sahur in Batam."
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>Our last sahur in Batam.</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                    <div className='article-content__text md:w-2/3 w-full h-auto text-justify'>
                        <p className='text-2xl'>Today is the long-awaited-day. The outcome of all the dedicated efforts we have put in over the past two months will be determined today.</p>
                        <br/>
                        <p className='text-lg'>Today, Saturday, April 1, 2023, the SAFMC 2023 Awarding Ceremony will take place at the Singapore University of Technology and Design (SUTD). We will depart by ferry at 07:30, so we had our pre-dawn meal (sahur) at Zeke Hotel. Today marks our last sahur as, following the championship announcement, we will immediately return to Indonesia. We also took some time to play billiards available right in the dining area of Zeke Hotel. We played billiards in a tag team format, where I teamed up with Sakti, while Yoga and Farrel became our opponents. In the end, we had to admit that Farrel and Yoga were more skilled in this game.</p>
                        <br/>
                        <p className='text-lg'>After enjoying our game, we finished the pre-dawn meal (sahur) that had been prepared and then returned to our respective rooms to get ready to head to the port and depart for Singapore.</p>
                    </div>
                </div>
                <div className='article-content-1 md:flex md:flex-row flex flex-col md:px-10 px-10 gap-x-4 gap-y-4 md:mb-20 mb-8'>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery1, "Play billiard in Zeke Hotel (Part 1).")}>
                            <img
                                src={Gallery1}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="Play billiard in Zeke Hotel (Part 1)."
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>Play billiard in Zeke Hotel (Part 1).</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery2, "Play billiard in Zeke Hotel (Part 2).")}>
                            <img
                                src={Gallery2}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="Play billiard in Zeke Hotel (Part 2)."
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>Play billiard in Zeke Hotel (Part 2).</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery3, "Play billiard in Zeke Hotel (Part 3).")}>
                            <img
                                src={Gallery3}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="Play billiard in Zeke Hotel (Part 3)."
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>Play billiard in Zeke Hotel (Part 3).</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                </div>
                <div className='article-break-1 flex justify-center items-center flex-col md:mb-36 mb-20'>
                    <hr className='w-5/6 mt-4 md:mb-12 mb-4  border-t border-b border-solid'/>
                    <p className='md:text-5xl text-2xl font-bold text-black md:px-44 px-10'>"My nerves have never been more anxious than this. This is the most uncertain feeling I have ever experienced."</p>
                    <hr className='w-5/6 mb-4 md:mt-12 mt-4 border-t border-b border-solid'/>
                </div>
                <div className='bg-fixed bg-center w-full h-96 md:mb-36 mb-20' style={{ backgroundImage: `url(${Pict2})`, backgroundSize: 'cover',  }}>
                </div>
                <div className='article-content-1 md:flex md:flex-row flex flex-col md:px-10 px-10 gap-x-4 gap-y-4 md:mb-16 mb-8'>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Pict3, "Arrived at Singapore University of Technology and Design (SUTD).")}>
                            <img
                                src={Pict3}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="Arrived at Singapore University of Technology and Design (SUTD)."
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>Arrived at Singapore University of Technology and Design (SUTD).</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                    <div className='article-content__text md:w-2/3 w-full h-auto text-justify'>
                        <p className='text-lg'>Finally, at 09:30 AM, we arrived at SUTD. Before entering the awarding hall, we took a stroll to explore the buildings at SUTD. There were many spots suitable for demonstrating drones. Some other participants were even given the chance to showcase the drones they competed with in front of the audience. We also met friends we had talked to during the competition at the Singapore Science Centre a few days ago.</p>
                        <br/>
                        <p className='text-lg'>Not long after our stroll, there was a sudden announcement for all participants to proceed to the awarding hall. Each participant had been assigned their respective seats. The event was attended by various important figures from Singapore, including Mr. Hee Cheng How (Singapore's Minister of Defense at that time). Our nervousness heightened upon hearing that the Minister of Defense of Singapore was present at the event. A few moments later, the championship announcement began. Winners from each category were called sequentially, starting from Category A, B, C, D, and then E. Until finally...</p>
                    </div>
                </div>
                <div className='article-content-1 md:flex md:flex-row flex flex-col md:px-10 px-10 gap-x-4 gap-y-4 md:mb-20 mb-8'>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery4, "SUTD's view (Part 1).")}>
                            <img
                                src={Gallery4}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="SUTD's view (Part 1)."
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>SUTD's view (Part 1).</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery5, "SUTD's view (Part 2).")}>
                            <img
                                src={Gallery5}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="SUTD's view (Part 2)."
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>SUTD's view (Part 2).</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery6, "SUTD's view (Part 3).")}>
                            <img
                                src={Gallery6}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="SUTD's view (Part 3)."
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>SUTD's view (Part 3).</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                </div>
                <div className='article-break-1 flex justify-center items-center flex-col md:mb-36 mb-20'>
                    <hr className='w-5/6 mt-4 md:mb-12 mb-4  border-t border-b border-solid'/>
                    <p className='md:text-5xl text-2xl font-bold text-black md:px-44 px-10'>"My heart felt like it stopped for a moment."</p>
                    <hr className='w-5/6 mb-4 md:mt-12 mt-4 border-t border-b border-solid'/>
                </div>
                <div className='bg-fixed bg-center w-full h-96 md:mb-36 mb-20' style={{ backgroundImage: `url(${Pict4})`, backgroundSize: 'cover',  }}>
                </div>
                <div className='article-content-1 md:flex md:flex-row flex flex-col md:px-10 px-10 gap-x-4 gap-y-4 md:mb-20 mb-8'>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Pict5, "We successfully won the bronze medal in the D2 - Autonomous Drone Category.")}>
                            <img
                                src={Pict5}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="We successfully won the bronze medal in the D2 - Autonomous Drone Category."
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>We successfully won the bronze medal in the D2 - Autonomous Drone Category.</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                    <div className='article-content__text md:w-2/3 w-full h-auto text-justify'>
                        <p className='text-lg'>Team Aksantara ITB, represented by Kak Zeke, Sakti, Yoga, Farrel, myself, and also Kak Dija, successfully won the bronze medal in the D2 – Autonomous Drone category. We couldn't believe the outcome at first; it was truly surprising. Finally, all the hard work we put in over the last few months paid off.</p>
                        <br/>
                        <p className='text-lg'>In conclusion, as the author of this series, I (Mohammad Rifqi Farhansyah) would like to express my gratitude to Allah SWT, my parents, friends who have always supported me, and the kind individuals around me. Without these parties, I might not have reached this point. Once again, thank you very much. Until we meet again in the next series of articles.</p>
                        <br/>
                        <div className='article-break-1 flex justify-start items-center flex-col'>
                            <hr className='w-full mt-4 mb-4  border-t border-b border-solid'/>
                        </div>
                        <p className='text-lg font-bold'><i>THE END</i></p>
                    </div>
                </div>
                <div className='article-content-1 md:flex md:flex-row flex flex-col md:px-10 px-10 gap-x-4 gap-y-4 md:mb-20 mb-8'>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery7, "ITB on stage (Part 1).")}>
                            <img
                                src={Gallery7}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="ITB on stage (Part 1)."
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>ITB on stage (Part 1).</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery8, "ITB on stage (Part 2).")}>
                            <img
                                src={Gallery8}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="ITB on stage (Part 2)."
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>ITB on stage (Part 2).</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery9, "ITB on stage (Part 3).")}>
                            <img
                                src={Gallery9}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="ITB on stage (Part 3)."
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>ITB on stage (Part 3).</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                </div>
                <div className='article-content-1 md:flex md:flex-row flex flex-col md:px-10 px-10 gap-x-4 gap-y-4 md:mb-20 mb-8'>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery10, "The obverse side of the medal....")}>
                            <img
                                src={Gallery10}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="The obverse side of the medal...."
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>The obverse side of the medal....</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery11, "The medalist.")}>
                            <img
                                src={Gallery11}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="The medalist."
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>The medalist.</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery12, "The reverse side of the medal....")}>
                            <img
                                src={Gallery12}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="The reverse side of the medal...."
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>The reverse side of the medal....</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                </div>
                <div className='article-content-1 md:flex md:flex-row flex flex-col md:px-10 px-10 gap-x-4 gap-y-4 md:mb-20 mb-8'>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery13, "Me with the medal.")}>
                            <img
                                src={Gallery13}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="Me with the medal."
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>Me with the medal.</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery14, "Our team with the medal.")}>
                            <img
                                src={Gallery14}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="Our team with the medal."
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>Our team with the medal.</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery15, "Sakti and I with the medal.")}>
                            <img
                                src={Gallery15}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="Sakti and I with the medal."
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>Sakti and I with the medal.</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                </div>
                <div className='article-content-1 md:flex md:flex-row flex flex-col md:px-10 px-10 gap-x-4 gap-y-4 md:mb-20 mb-8'>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery16, "Post-awarding ceremony (Part 1).")}>
                            <img
                                src={Gallery16}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="Post-awarding ceremony (Part 1)."
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>Post-awarding ceremony (Part 1).</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery17, "Post-awarding ceremony (Part 2).")}>
                            <img
                                src={Gallery17}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="Post-awarding ceremony (Part 2)."
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>Post-awarding ceremony (Part 2).</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery18, "Post-awarding ceremony (Part 3).")}>
                            <img
                                src={Gallery18}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="Post-awarding ceremony (Part 3)."
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>Post-awarding ceremony (Part 3).</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                </div>
                <div className='article-break-1 flex justify-center items-center flex-col md:mb-8 mb-4'>
                    <hr className='w-5/6 mt-4 md:mb-12 mb-4  border-t border-b border-solid'/>
                    <p className='md:text-5xl text-2xl font-bold text-black md:px-44 px-10'>"Good Bye Singapore! Hope to see you again as soon as possible!"</p>
                    <hr className='w-5/6 mb-4 md:mt-12 mt-4 border-t border-b border-solid'/>
                </div>
                <div className='article-content-1 md:flex md:flex-row flex flex-col justify-center md:px-10 px-10 gap-x-12 gap-y-4 md:mb-20 mb-8'>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(SertifD1, "D1 (Semi Autonomous Drone) - Certificate")}>
                            <img
                                src={SertifD1}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="D1 (Semi Autonomous Drone) - Certificate"
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>D1 (Semi Autonomous Drone) - Certificate</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(SertifD2, "D2 (Autonomous Drone) - Certificate")}>
                            <img
                                src={SertifD2}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="D2 (Autonomous Drone) - Certificate"
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>D2 (Autonomous Drone) - Certificate</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex justify-center items-center px-12 md:mb-8 mb-4 gap-x-8'>
                <Link to="/safmc/safmc-part6" className='md:text-xl text-lg text-textRed font-bold flex items-center hover:scale-105'>
                    <FaAngleDoubleLeft className='w-3 h-3 text-rgba-28-5-179 inline-block mr-1' />
                    PREV 
                </Link>
                <p className='md:text-xl text-lg text-textGrey font-bold flex items-center'>
                    NEXT 
                    <FaAngleDoubleRight className='w-3 h-3 text-textGrey inline-block ml-1' />
                </p>
            </div>
            <div className='bg-fixed bg-center w-full h-96' style={{ backgroundImage: `url(${Gallery14})`, backgroundSize: 'cover',  }}>
            </div>
            <CommentListExample postId={7}/>
            <ImageModal
                showModal={modalVisible}
                closeModal={closeModal}
                imageUrl={selectedImage}
                imageAlt={selectedAlt}
            />
            <Footer/>
            {arrowTransition((style, item) =>
                item ? (
                <animated.div
                    style={style}
                    className="fixed bottom-5 right-5 cursor-pointer z-10"
                    onClick={scrollToTop}
                >
                    <FaArrowCircleUp
                    size={isMd ? 32 : 24}
                    className="text-[#c0c5ca] hover:scale-110"
                    />
                </animated.div>
                ) : null
            )}
        </div>
    );
};

export default SAFMCPART7