import React, { useEffect, useState } from 'react';
import SertifSAFMCD2 from '../assets/SertifikatSAFMCD2.pdf';
import SertifSAFMCD1 from '../assets/SertifikatSAFMCD1.pdf';

const Award: React.FC = () => {
    const [isMd, setIsMd] = useState(window.matchMedia('(min-width: 67.5rem)').matches);

    useEffect(() => {
        const mediaQuery = window.matchMedia('(min-width: 67.5rem)');
        const updateIsMd = () => {
            setIsMd(mediaQuery.matches);
        };
    
        mediaQuery.addListener(updateIsMd);
        return () => {
            mediaQuery.removeListener(updateIsMd);
        };
    }, []);

    return(
        <div className=' flex flex-col justify-center items-center' >
            <br/>
            <p className='text-textGrey font-condensed text-center font-extrabold text-lg' id="award" >
            AWARD & COMPETITION
            </p>
            <br/>
            {isMd ?(
            <ul className="text-textGrey text-md text-left font-condensed font-extralight w-[65.625rem] pl-5 h-auto list-disc">
                <li>
                [International]<b> <a href={SertifSAFMCD2} className='text-textRed hover:underline' title='Download the certificate here!'>Bronze Medal</a> at <a href="https://www.safmc.com.sg/" className='text-textRed hover:underline' title="SAFMC's Website">Singapore Amazing Flying Machine Competition</a> 2023 </b> - D2 Autonomous Drone Category, March 2023
                </li>
                <li>
                [International]<b> <a href={SertifSAFMCD1} className='text-textRed hover:underline' title='Download the certificate here!'>Finalist</a> at <a href="https://www.safmc.com.sg/" className='text-textRed hover:underline' title="SAFMC's Website">Singapore Amazing Flying Machine Competition</a> 2023 </b> - D1 Semi Autonomous Drone Category, March 2023
                </li>
                <li>
                [International] <b>Ganesha Karya </b> from ITB 2023 for International Competition Winner, October 2023
                </li>
                <li>
                [National] <b>Garuda ACE Fellowship</b> from Indonesia MoECRT, December 2023
                </li>
                <li>
                [National] <b>HanaBank Korea</b> scholarship awardee, November 2023
                </li>
                <li>
                [National] <b>Granted scholarships for monthly expenses</b> from Akabri-70 Foundation, August 2021
                </li>
                <li>
                [National] <b>Granted a full scholarship for my tuition fees from KIP-K </b>during my study in ITB, August 2021
                </li>
                <li>
                [National]<b> 1st Place at <a href='https://www.instagram.com/p/B29L0dLHmRg/?hl=en' className='text-textRed hover:underline' title='Watch my video!'>Ruangguru Champion Season 1</a> </b>-  won $1000 USD, August 2019
                </li>
                <li>
                [Regional]<b> 1st Place and Best Speaker at <a href="https://sma.pusatprestasinasional.kemdikbud.go.id/ldbinsdc/#about" className='text-textRed hover:underline' title="LDBI's Website">Lomba Debat Bahasa Indonesia (LDBI)</a> </b>, May 2019
                </li>
                <li>
                [Regional]<b> 1st Place at <a href='https://osn-onmipa-bpti.kemdikbud.go.id/osn.html' className='text-textRed hover:underline' title="KSN's Website">Kompetisi Sains Nasional</a> (Physics) </b>, April 2019
                </li>
            </ul>
            ) : (
            <ul className="text-textGrey text-sm text-left font-condensed font-extralight md:w-[95vw] sm:w-[93vw] h-auto list-disc">
                <li className='mx-10'>
                [International]<b> <a href={SertifSAFMCD2} className='text-textRed hover:underline' title='Download the certificate here!'>Bronze Medal</a> at <a href="https://www.safmc.com.sg/" className='text-textRed hover:underline' title="SAFMC's Website">Singapore Amazing Flying Machine Competition</a> 2023 </b> - D2 Autonomous Drone Category, March 2023
                </li>
                <li className='mx-10'>
                [International]<b> <a href={SertifSAFMCD1} className='text-textRed hover:underline' title='Download the certificate here!'>Finalist</a> at <a href="https://www.safmc.com.sg/" className='text-textRed hover:underline' title="SAFMC's Website">Singapore Amazing Flying Machine Competition</a> 2023 </b> - D1 Semi Autonomous Drone Category, March 2023
                </li>
                <li className='mx-10'>
                [International] <b>Ganesha Karya </b> from ITB 2023 for International Competition Winner, October 2023
                </li>
                <li className='mx-10'>
                [National] <b>Garuda ACE Fellowship</b> from Indonesia MoECRT, December 2023
                </li>
                <li className='mx-10'>
                [National] <b>HanaBank Korea</b> scholarship awardee, November 2023
                </li>
                <li className='mx-10'>
                [National] <b>Granted scholarships for monthly expenses</b> from Akabri-70 Foundation, August 2021
                </li>
                <li className='mx-10'>
                [National] <b>Granted a full scholarship for my tuition fees from KIP-K </b>during my study in ITB, August 2021
                </li>
                <li className='mx-10'>
                [National]<b> 1st Place at <a href='https://www.instagram.com/p/B29L0dLHmRg/?hl=en' className='text-textRed hover:underline' title='Watch my video!'>Ruangguru Champion Season 1</a> </b>-  won $1000 USD, August 2019
                </li>
                <li className='mx-10'>
                [Regional]<b> 1st Place and Best Speaker at <a href="https://sma.pusatprestasinasional.kemdikbud.go.id/ldbinsdc/#about" className='text-textRed hover:underline' title="LDBI's Website">Lomba Debat Bahasa Indonesia (LDBI)</a> </b>, May 2019
                </li>
                <li className='mx-10'>
                [Regional]<b> 1st Place at <a href='https://osn-onmipa-bpti.kemdikbud.go.id/osn.html' className='text-textRed hover:underline' title="KSN's Website">Kompetisi Sains Nasional</a> (Physics) </b>, April 2019
                </li>
            </ul>
            )}
            <br/>
        </div>
    );
};

export default Award