import React, { useEffect, useState } from 'react';
import LoadingBar from 'react-top-loading-bar';
import { useTransition, animated } from 'react-spring';
import { FaArrowCircleUp, FaAngleDoubleLeft, FaAngleDoubleRight, FaEye } from 'react-icons/fa';
import NavbarDetailBlog from '../../../components/navbarDetailBlog.tsx';
import HeaderDetailBlog from '../utils/headerDetailBlog.tsx';
import Footer from '../../../components/footer.tsx';
import { Link } from 'react-router-dom';
import ImageModal from '../utils/imageModal.tsx';
import CommentListExample from '../../../components/comments/commentListExample.tsx';


import usePreventZoom from '../../../preventZoom.js';

import Header from '../../../assets/Blog/SAFMC/SAFMC_PART3/Detail/HEADER_DETAIL_SAFMC_PART3.webp';
import Pict1 from "../../../assets/Blog/SAFMC/SAFMC_PART3/Detail/1.webp";
import Pict2 from "../../../assets/Blog/SAFMC/SAFMC_PART3/Detail/2.webp";
import Pict3 from "../../../assets/Blog/SAFMC/SAFMC_PART3/Detail/3.webp";
import Pict4 from "../../../assets/Blog/SAFMC/SAFMC_PART3/Detail/4.webp";
import Pict5 from "../../../assets/Blog/SAFMC/SAFMC_PART3/Detail/5.webp";
import Pict6 from "../../../assets/Blog/SAFMC/SAFMC_PART3/Detail/6.webp";
import Pict7 from "../../../assets/Blog/SAFMC/SAFMC_PART3/Detail/7.webp";
import Pict8 from "../../../assets/Blog/SAFMC/SAFMC_PART3/Detail/8.webp";

import Gallery1 from "../../../assets/Blog/SAFMC/SAFMC_PART3/Gallery/1.webp";
import Gallery2 from "../../../assets/Blog/SAFMC/SAFMC_PART3/Gallery/2.webp";
import Gallery3 from "../../../assets/Blog/SAFMC/SAFMC_PART3/Gallery/3.webp";
import Gallery4 from "../../../assets/Blog/SAFMC/SAFMC_PART3/Gallery/4.webp";
import Gallery5 from "../../../assets/Blog/SAFMC/SAFMC_PART3/Gallery/5.webp";
import Gallery6 from "../../../assets/Blog/SAFMC/SAFMC_PART3/Gallery/6.webp";
import Gallery7 from "../../../assets/Blog/SAFMC/SAFMC_PART3/Gallery/7.webp";
import Gallery8 from "../../../assets/Blog/SAFMC/SAFMC_PART3/Gallery/8.webp";
import Gallery9 from "../../../assets/Blog/SAFMC/SAFMC_PART3/Gallery/9.webp";
import Gallery10 from "../../../assets/Blog/SAFMC/SAFMC_PART3/Gallery/10.webp";
import Gallery11 from "../../../assets/Blog/SAFMC/SAFMC_PART3/Gallery/11.webp";
import Gallery12 from "../../../assets/Blog/SAFMC/SAFMC_PART3/Gallery/12.webp";

const SAFMCPART3: React.FC = () => {
    const [isMd, setIsMd] = useState(window.matchMedia('(min-width: 900px)').matches);
    const [showScrollToTop, setShowScrollToTop] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
    const [selectedAlt, setSelectedAlt] = useState('');
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        setProgress(30);
        setTimeout(() => {
            setProgress(100);
        }, 600);
    }, []);
  
    const openModal = (imageUrl, imageAlt) => {
        setSelectedImage(imageUrl);
        setSelectedAlt(imageAlt);
        setModalVisible(true);
    };
  
    const closeModal = () => {
        setSelectedImage('');
        setSelectedAlt('');
        setModalVisible(false);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const mediaQuery = window.matchMedia('(min-width: 900px)');
        const updateIsMd = () => {
            setIsMd(mediaQuery.matches);
        };

        mediaQuery.addListener(updateIsMd);
        return () => {
            mediaQuery.removeListener(updateIsMd);
        };
    }, []);

    const handleScroll = () => {
        if (window.scrollY > 350) {
            setShowScrollToTop(true);
        } else {
            setShowScrollToTop(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const arrowTransition = useTransition(showScrollToTop, {
        from: { opacity: 0, transform: 'translateY(100%)' },
        enter: { opacity: 1, transform: 'translateY(0%)' },
        leave: { opacity: 0, transform: 'translateY(100%)' },
        config: { tension: 300, friction: 20 },
      });

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, {passive: false});
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    usePreventZoom();

    return(
        <div className='App'>
            <NavbarDetailBlog/>
            <div className='pt-20 min-w-screen font-condensed text-textGrey'>
                {/* Loading Bar */}
                <LoadingBar color="#8C001f" progress={progress} onLoaderFinished={() => setProgress(0)} />
                <section className='hidden w-64 mr-10 group transform hover:translate-y-[-5px] transition-transform duration-300 ease-in-out'>
                        <script type='text/javascript' id='clustrmaps' src='//cdn.clustrmaps.com/map_v2.js?cl=e1e1e1&w=a&t=tt&d=mIBEHA96Sa9wc29y0yefyCb9wP8YgephePOwDaQRDk0&co=ffffff&cmo=8c001f&cmn=d4201e&ct=394048'></script>
                </section>
                <HeaderDetailBlog backgroundImage={Header} title="D1 - Semi Autonomous Drone Competition" date="24" year="December, 2023" backgroundPosition="left center"></HeaderDetailBlog>
                <div className='author-article text-end pr-4 pt-8 pb-4 text-xs'>
                    <p className='font-condensed text-textGrey'>by Mohammad Rifqi Farhansyah | Singapore Amazing Flying Machine Competition 2023</p>
                </div>
                <div className='article-content-1 md:flex md:flex-row flex flex-col md:px-10 px-10 gap-x-4 gap-y-4 md:mb-16 mb-8'>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Pict1, 'Sakti with his laptop, tried to find the bug in the program')}>
                            <img
                                src={Pict1}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="Sakti with his laptop, tried to find the bug in the program"
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto'>
                            <p className='text-lg font-condensed text-start'><i>Sakti with his laptop, tried to find the bug in the program</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi | Sandpiper Hotel, Little India, Singapore</i></p>
                        </div>
                    </div>
                    <div className='article-content__text md:w-2/3 w-full h-auto text-justify'>
                        <p className='text-2xl'>Tomorrow is quite a tense day for us as we will be participating in the competition in the D1 category – Semi Autonomous Drone at the Science Center, Singapore. Therefore, tonight we will not be sleeping to prepare for the competition tomorrow.</p>
                        <br/>
                        <p className='text-lg'>We arrived at the hotel around 23:00 WIB. After that, we immediately prepared all the necessities for the next day. We conducted testing on the program we had created, fixed some details on the drone, and so on. Our competition is scheduled to start at 08:00 WIB the following day, making tonight the last night to prepare all the equipment for the D1 category – Semi Autonomous Drone competition. In this competition, we will only use 1 drone and also 1 hand gesture controller (as a command tool for the drone). Suddenly, that night, there was an issue with the controller we had. However, the problem was eventually resolved 3 hours before our departure to the competition venue.</p>
                        <br/>
                        <p className='text-lg'>After fixing some bugs, we went out to find sahur (pre-dawn meal). It was quite challenging to find halal food here, but eventually, we found Hi Five, a Muslim restaurant in the Little India area. Honestly, I didn't know what food I actually ordered. I just made sure to order sweet iced tea; at least, the drink could help anything go down my stomach :) .</p>
                    </div>
                </div>
                <div className='article-content-1 md:flex md:flex-row flex flex-col md:px-10 px-10 gap-x-4 gap-y-4 md:mb-20 mb-8'>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery1, 'Hi Five`s Menu (part 1)')}>
                            <img
                                src={Gallery1}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="Hi Five`s Menu (part 1)"
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>Hi Five's Menu (part 1)</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery2, 'Hi Five`s Menu (part 2)')}>
                            <img
                                src={Gallery2}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="Hi Five`s Menu (part 2)"
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>Hi Five's Menu (part 2)</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery3, 'In Java, we just called it "Jangan Jipang"')}>
                            <img
                                src={Gallery3}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="In Java, we just called it `Jangan Jipang`"
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>In Java, we just called it "Jangan Jipang"</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                </div>
                <div className='article-break-1 flex justify-center items-center flex-col md:mb-36 mb-20'>
                    <hr className='w-5/6 mt-4 md:mb-12 mb-4  border-t border-b border-solid'/>
                    <p className='md:text-5xl text-2xl font-bold text-black md:px-44 px-10'>"Now, I already missed my Bakso, Mie Ayam, Blendrang, Kupat Tahu, etc."</p>
                    <hr className='w-5/6 mb-4 md:mt-12 mt-4 border-t border-b border-solid'/>
                </div>
                <div className='bg-fixed bg-center w-full h-96 md:mb-36 mb-20' style={{ backgroundImage: `url(${Pict2})`, backgroundSize: 'cover',  }}>
                </div>
                <div className='article-content-1 md:flex md:flex-row flex flex-col md:px-10 px-10 gap-x-4 gap-y-4 md:mb-16 mb-8'>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Pict3, 'Our Suitcase is Full With All of the Equipment')}>
                            <img
                                src={Pict3}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="Our Suitcase is Full With All of the Equipment"
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto'>
                            <p className='text-lg font-condensed text-start'><i>Our suitcase is full with all of the equipment</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                    <div className='article-content__text md:w-2/3 w-full h-auto text-justify'>
                        <p className='text-lg'>At 06:30 AM, we headed to Bugis MRT station (the nearest MRT station from our hotel) to go directly to the Singapore Science Centre. We carried all the necessary items, with our backs and both hands full of the things we were bringing. Even though we had a large pink suitcase, it was still not enough to carry all our belongings. Eventually, each of us had to continue carrying bags with the necessary items. At that time, my task was to carry one of the drones while also holding a bag containing a laptop and other technical necessities. It felt so tiring and thirsty when we arrived at the Singapore Science Centre. However, since we were fasting, we tried to endure the thirst. </p>
                    </div>
                </div>
                <div className='article-content-1 md:flex md:flex-row flex flex-col md:px-10 px-10 gap-x-4 gap-y-4 md:mb-16 mb-8'>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Pict4, 'We arrived at Singapore Science Centre')}>
                            <img
                                src={Pict4}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="We arrived at Singapore Science Centre"
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto'>
                            <p className='text-lg font-condensed text-start'><i>We arrived at Singapore Science Centre</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                    <div className='article-content__text md:w-2/3 w-full h-auto text-justify'>
                        <p className='text-lg'>Upon arriving at the Singapore Science Centre, our team's LO approached us and directed us to the competition briefing location. Initially, we walked through a fairly long, dark corridor until we reached a spacious hall that already housed other participants. The LO then provided instructions on where we should sit, and we took our designated seats. After a while, a draw was conducted to determine the presentation order for each team. Suddenly, we found out that we were scheduled to present second. This made us a bit nervous, considering we were still tired and thirsty. Additionally, our bodies were still quite sweaty from the long walk. Nevertheless, we tried to stay focused and prepared for the presentation. </p>
                    </div>
                </div>
                <div className='article-content-1 md:flex md:flex-row flex flex-col md:px-10 px-10 gap-x-4 gap-y-4 md:mb-20 mb-8'>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery4, 'Inside the auditorium at the Singapore Science Centre')}>
                            <img
                                src={Gallery4}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="Inside the auditorium at the Singapore Science Centre"
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto'>
                            <p className='text-lg font-condensed text-start'><i>Inside the auditorium at the Singapore Science Centre</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery5, 'The team leader is currently giving instructions to us.')}>
                            <img
                                src={Gallery5}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="The team leader is currently giving instructions to us."
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto'>
                            <p className='text-lg font-condensed text-start'><i>The team leader is currently giving instructions to us.</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery6, 'Sakti and I are discussing the points that we want to include in the presentation.')}>
                            <img
                                src={Gallery6}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="Sakti and I are discussing the points that we want to include in the presentation."
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto'>
                            <p className='text-lg font-condensed text-start'><i>Sakti and I are discussing the points that we want to include in the presentation.</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                </div>
                <div className='article-break-1 flex justify-center items-center flex-col md:mb-36 mb-20'>
                    <hr className='w-5/6 mt-4 md:mb-12 mb-4  border-t border-b border-solid'/>
                    <p className='md:text-5xl text-2xl font-bold text-black md:px-44 px-10'>"My heart was beating quite fast at that moment, perhaps because it was my first presentation in an international competition."</p>
                    <hr className='w-5/6 mb-4 md:mt-12 mt-4 border-t border-b border-solid'/>
                </div>
                <div className='article-content-1 md:flex md:flex-row flex flex-col md:px-10 px-10 gap-x-4 gap-y-4 md:mb-10 mb-8'>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Pict5, 'Present our work to the judges')}>
                            <img
                                src={Pict5}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="Present our work to the judges"
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto'>
                            <p className='text-lg font-condensed text-start'><i>Present our work to the judges</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                    <div className='article-content__text md:w-2/3 w-full h-auto text-justify'>
                        <p className='text-lg'>The time for the presentation finally arrived. We divided the presentation into 5 parts: I would explain the battery and sensor suite used, Sakti would cover the On-Board computer, Kak Zeke would elaborate on the creativity factor, Yoga would discuss the platform choice, and Farrel would delve into the mechanical design.</p>
                        <br/>
                        <p className='text-lg'>We designed our own aerial platform for this mission. The drone has a quad-X configuration with a 450mm wheelbase with the center of gravity positioned in the middle of the frame. One feature of our design that adds to the stability is the tilted motor mounts. By having the motors positioned at an angle, this creates an effect similar to a dihedral, causing the line of thrust of the four motors to converge above the drone, resulting in better pitch and roll stability. For the motors, we chose 630KV motors paired with 12x55 carbon propellers, which are capable of producing thrust up to 6kg. In normal flight condition, the powertrain runs at high efficiency, allowing for maximum flight time up to 14 minutes.</p>
                        <br/>
                        <p className='text-lg'>The construction of the airframe predominantly consists of carbon fibre tubes, laser cut 2mm acrylic plates, and 3d printed brackets. These materials are chosen for their great mechanical properties and low mass in order to achieve a weight of approximately 1.75kg while still retaining structural integrity and rigidity. Specific for this mission, the drone is fitted with a payload dropping mechanism. This mechanism is constructed out of laser cut balsa wood driven by servo.</p>
                        <br/>
                        <p className='text-lg'>On board the drone is an Odroid XU4 running ubuntu. This on board computer processes the raw data given by the T265 and feeds position, velocity, and orientation information to the flight controller. It also serves as the telemetry link between the FC and GCS over UDP. These information are fed to the flight controller, which in our case is a Pixhawk Cube. This F7 based FC is equipped with triple redundant IMU sensors housed in a compact form factor.</p>
                        <br/>
                        <p className='text-lg'>On board the drone are three FPV cameras. One facing forward, one facing down to the PDM, and one behind the PDM. The pilot is able to cycle through the camera views with a command from the glove. These camera angles allow the pilot to see where the drone is going to navigate the course, and look down to where the payload is being picked up or dropped.</p>
                        <br/>
                    </div>
                </div>
                <div className='article-content-1 md:flex md:flex-row flex flex-col md:px-10 px-10 gap-x-4 gap-y-4 md:mb-20 mb-8'>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Pict6, 'Please ignore my face')}>
                            <img
                                src={Pict6}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="Please ignore my face"
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto'>
                            <p className='text-lg font-condensed text-start'><i>Please ignore my face</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                    <div className='article-content__text md:w-2/3 w-full h-auto text-justify'>
                        <p className='text-lg'>To power the whole drone, we use a 3300mah 4s lipo battery. This battery can provide sufficient current and voltage to drive the motors, power the flight controller, on board computer, and sensors while sustaining flight. Because the drone will be flown in loiter mode, the drone needs accurate altitude and position information. Altitude data is fed to the flight controller by a LiDar range finder, which can measure the drone’s altitude with high accuracy, while position data is given by T265 tracking camera. Initially, we used a regular optical flow sensor. However, we quickly found out that it cannot provide accurate enough position information under low light or indoor condition, causing the drone to drift. The T265 tracking camera fixed this problem with its accurate and precise visual odometry capabilities.</p>
                        <br/>
                        <p className='text-lg'>And the last one is about the creativity factor that based on: What type of wearable technology is used and how it is integrated with the flight control to tackle the obstacle course and how the payload is carried and how successful payload release/retrieval is achieved.</p>
                        <br/>
                        <p className='text-lg'>The controller used is based on a glove for easy and intuitive use. To activate the control, the pilot must touch their thumb on the side of the index finger. Roll/pitch/yaw on the drone are controlled by tilting the user’s hand in the roll pitch and yaw direction. The rates of roll, pitch, and yaw are made constant so that the control is not too sensitive. Releasing the thumb from the index finger will disable control input and neutralise the control, causing the drone to hover in place. The battery is placed on the arm so that the hand is not burdened while moving. The NRF24L01 radio communication module is used with a frequency of 2.4 GHz for real-time data transmission, sufficient throughput, and a sufficiently far range. In addition, the 2.4 GHz frequency allows for integration with existing receivers for future development. Audio feedback is given because the pilot will use an FPV camera so the pilot will not be able to visually monitor the glove's status.</p>
                        <br/>
                        <p className='text-lg'>At least two concept iterations have been done on the finger. The haptic-feedback concept with potentiometer was changed to an exposed-pad because binary data is required, namely zero/one. Potentiometers also require calibration for each user, while exposed-pads do not. This can reduce the potential for detection errors. In addition, the size can be made smaller. Safety is the top priority in glove development. Initially, a module was made that can take over control with the TX. In addition, drone movements from the glove are also made very slow. Before the test flight, important signals have been validated with an oscilloscope and dry test Pixhawk.</p>
                        <br/>
                        <p className='text-lg'>Our program will give position, velocity, and orientation data from the T265 camera and send it to the Pixhawk flight controller via telemetry link messages. The program then enters a loop where it waits for new frames from the T265 camera, processes the pose data, and sends telemetry link messages to the Pixhawk flight controller. In particular, the script calculates the pose of the camera in aeronautic coordinates, transforms the velocity to aeronautic coordinates, and sends telemetry link messages containing the position, velocity, and orientation data to the Pixhawk flight controller. The script also checks for pose jumps and sends a warning message to the GCS if a jump is detected. Our program also has a mechanism to gracefully terminate the script if an interrupt signal is received.</p>
                        <br/>
                        <p className='text-lg'>First it connects to the T265 camera and configure it for operation. Then defines several functions that are used throughout the script. By using functions we have made, we can track the messages in the console with a timestamp. We also have a function that is used to send MAVLink messages to the Ground Control Station (GCS) and handle the  incoming MAVLink messages from the Pixhawk flight controller.</p>
                        <br/>
                    </div>
                </div>
                <div className='article-content-1 md:flex md:flex-row flex flex-col md:px-10 px-10 gap-x-4 gap-y-4 md:mb-20 mb-8'>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery7, 'The hand gestures controller')}>
                            <img
                                src={Gallery7}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="The hand gestures controller"
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto'>
                            <p className='text-lg font-condensed text-start'><i>The hand gestures controller</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery8, 'Our drones')}>
                            <img
                                src={Gallery8}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="Our drones"
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto'>
                            <p className='text-lg font-condensed text-start'><i>Our drones</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery9, 'Make sure the condition using Mission Planner')}>
                            <img
                                src={Gallery9}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="Make sure the condition using Mission Planner"
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto'>
                            <p className='text-lg font-condensed text-start'><i>Make sure the condition using Mission Planner</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                </div>
                <div className='bg-fixed bg-center w-full h-96 md:mb-36 mb-20' style={{ backgroundImage: `url(${Pict7})`, backgroundSize: 'cover',  }}>
                </div>
                <div className='article-content-1 md:flex md:flex-row flex flex-col md:px-10 px-10 gap-x-4 gap-y-4 md:mb-20 mb-8'>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Pict8, 'Prepare for the show')}>
                            <img
                                src={Pict8}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="Prepare for the show"
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto'>
                            <p className='text-lg font-condensed text-start'><i>Prepare for the show</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                    <div className='article-content__text md:w-2/3 w-full h-auto text-justify'>
                        <p className='text-lg'>After the presentation, we headed to the competition area to demonstrate the drone we had built. In the D1 category, we were asked to play Tic-Tac-Toe using a single drone. At point A, there was a bag with hooks that we would use as game pieces to be moved to point B, similar to the traditional Tic-Tac-Toe game. The difference was in the turn-taking rule; our team didn't take turns with another team but moved in parallel. The fastest team to form a line (horizontal, vertical, or diagonal) would win the game. The challenge was to move the pieces from point A to point B, and there were several obstacles that our drone had to navigate.</p>
                        <div className='article-break-1 flex justify-start items-center flex-col'>
                            <hr className='w-full mt-4 mb-4  border-t border-b border-solid'/>
                        </div>
                        <p className='text-lg font-bold'><i>to be continued...</i></p>
                    </div>
                </div>
                <div className='article-content-1 md:flex md:flex-row flex flex-col md:px-10 px-10 gap-x-4 gap-y-4 md:mb-20 mb-8'>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery10, 'Sakti and I when setting all of the connections')}>
                            <img
                                src={Gallery10}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="Sakti and I when setting all of the connections"
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto'>
                            <p className='text-lg font-condensed text-start'><i>Sakti and I when setting all of the connections</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery11, 'Our leaflets')}>
                            <img
                                src={Gallery11}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="Our leaflets"
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto'>
                            <p className='text-lg font-condensed text-start'><i>Our leaflets</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery12, 'Say hmmmmm!')}>
                            <img
                                src={Gallery12}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="Say hmmmmm!"
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto'>
                            <p className='text-lg font-condensed text-start'><i>Say hmmmmm!</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                </div>
                <div className='article-break-1 flex justify-center items-center flex-col md:mb-8 mb-4'>
                    <hr className='w-5/6 mt-4 md:mb-12 mb-4  border-t border-b border-solid'/>
                    <p className='md:text-5xl text-2xl font-bold text-black md:px-44 px-10'>"Do Your Best and Let God Do the Rest!"</p>
                    <hr className='w-5/6 mb-4 md:mt-12 mt-4 border-t border-b border-solid'/>
                </div>
            </div>
            <div className='flex justify-center items-center px-12 md:mb-8 mb-4 gap-x-8'>
                <Link to="/safmc/safmc-part2" className='md:text-xl text-lg text-textRed font-bold flex items-center hover:scale-105'>
                    <FaAngleDoubleLeft className='w-3 h-3 text-rgba-28-5-179 inline-block mr-1' />
                    PREV 
                </Link>
                <Link to="/safmc/safmc-part4" className='md:text-xl text-lg text-textRed font-bold flex items-center hover:scale-105'>
                    NEXT 
                    <FaAngleDoubleRight className='w-3 h-3 text-rgba-28-5-179 inline-block ml-1' />
                </Link>
            </div>
            <div className='bg-fixed w-full h-96' style={{ backgroundImage: `url(${Gallery9})`, backgroundSize: 'cover',  }}>
            </div>
            <CommentListExample postId={3}/>
            <ImageModal
                showModal={modalVisible}
                closeModal={closeModal}
                imageUrl={selectedImage}
                imageAlt={selectedAlt}
            />
            <Footer/>
            {arrowTransition((style, item) =>
                item ? (
                <animated.div
                    style={style}
                    className="fixed bottom-5 right-5 cursor-pointer z-10"
                    onClick={scrollToTop}
                >
                    <FaArrowCircleUp
                    size={isMd ? 32 : 24}
                    className="text-[#c0c5ca] hover:scale-110"
                    />
                </animated.div>
                ) : null
            )}
        </div>
    );
};

export default SAFMCPART3