import React, { useEffect, useState } from 'react';
import Logo from '../assets/WebsiteLogoNoBG.webp';
import { FaAngleDoubleLeft } from 'react-icons/fa';
import { Link } from 'react-scroll';
import { Route, Link as RouterLink } from 'react-router-dom';

const Header: React.FC = () => {
  const [isMd, setIsMd] = useState(window.matchMedia('(min-width: 768px)').matches);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(min-width: 768px)');
    const updateIsMd = () => {
      setIsMd(mediaQuery.matches);
    };

    mediaQuery.addListener(updateIsMd);
    return () => {
      mediaQuery.removeListener(updateIsMd);
    };
  }, []);

  const [open, setOpen] = useState<boolean>(false);
  const [isBackToBlogHovered, setIsBackToBlogHovered] = useState(false);

  const offset = isMd ? -100 : -100;
  const handleLogoClick = () => {
    window.location.href = 'https://rifqifarhansyah.id';
  };

  return (
    <div className='shadow-md w-screen fixed top-0 left-0 z-[10000]'>
      <div className='flex items-center justify-between bg-white py-4 px-8 relative'>
        {/* logo section */}
        <div className='font-bold text-sm cursor-pointer flex items-center gap-1' onClick={handleLogoClick}>
          <img src={Logo} alt="Rifqi Website Logo" className='w-12 h-12' width="12" height="12" loading="lazy" />
        </div>
        {/* link items */}
        <ul
          className={`flex items-center pb-0 static bg-white z-auto left-0 w-auto pl-0 transition-all duration-300`}
        >
          <li className='ml-8 my-0 hover:text-primary'>
          {isMd ? 
            <RouterLink
              to="/safmc"
              className={`inline-flex items-center font-condensed px-5 py-1 border-opacity-25 border text-sm font-thin hover:bg-hoverDarkGrey hover:border-opacity-50 rounded-full uppercase focus:outline-none relative `}
              onMouseEnter={() => setIsBackToBlogHovered(true)}
              onMouseLeave={() => setIsBackToBlogHovered(false)}
            >
              <FaAngleDoubleLeft className={`w-3 h-3 text-rgba-28-5-179 ml-2 absolute left-0 transition-all duration-300 ${isBackToBlogHovered ? 'opacity-100' : 'opacity-0'}`} />
              <span className={`transition-all duration-300 ${isBackToBlogHovered ? 'pl-2 translate-x-1' : 'pl-1'}`}>Back to Blog</span>
            </RouterLink>
            :
            <RouterLink
              to="/safmc"
              className={`inline-flex items-center font-condensed px-2 py-1 border-opacity-25 border text-sm font-thin hover:bg-hoverDarkGrey hover:border-opacity-50 rounded-full uppercase focus:outline-none w-11/12 justify-center mr-4`}
            >
              <FaAngleDoubleLeft className='w-3 h-3 text-rgba-28-5-179 mr-2 ' />Back to Blog
            </RouterLink>
            }
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Header;
