import React from 'react';

interface HeaderProps {
  backgroundImage: string;
  title: string;
  backgroundPosition?: string;
}

const Header: React.FC<HeaderProps> = ({ backgroundImage, title ,backgroundPosition = 'left bottom' }) => {
    const headerStyle: React.CSSProperties = {
        background: `url(${backgroundImage}) no-repeat`,
        backgroundPosition,
        backgroundSize: 'cover',
    };

    const whiteBackgroundStyle: React.CSSProperties = {
        backgroundColor: 'rgba(255, 255, 255, 0.35)', // 25% opacity white
    };

    return (
        <header style={headerStyle} className='bg-fixed flex items-center justify-center h-96 font-condensed'>
            <div style={whiteBackgroundStyle} className='bg-white h-auto w-full p-4 mt-44 text-center'>
                <h1 className="text-4xl font-bold opacity-70 text-black">{title}</h1>
            </div>
        </header>
    );
};

export default Header;
