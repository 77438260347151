import React, { useEffect, useState } from 'react';
import LoadingBar from 'react-top-loading-bar';
import { useTransition, animated } from 'react-spring';
import { FaArrowCircleUp, FaAngleDoubleLeft, FaAngleDoubleRight, FaEye } from 'react-icons/fa';
import NavbarDetailBlog from '../../../components/navbarDetailBlog.tsx';
import HeaderDetailBlog from '../utils/headerDetailBlog.tsx';
import Footer from '../../../components/footer.tsx';
import { Link } from 'react-router-dom';
import ImageModal from '../utils/imageModal.tsx';
import CommentListExample from '../../../components/comments/commentListExample.tsx';


import usePreventZoom from '../../../preventZoom.js';

import Header from '../../../assets/Blog/SAFMC/SAFMC_PART5/Detail/HEADER_DETAIL_SAFMC_PART5.webp';
import Pict1 from "../../../assets/Blog/SAFMC/SAFMC_PART5/Detail/1.webp";
import Pict2 from "../../../assets/Blog/SAFMC/SAFMC_PART5/Detail/2.webp";
import Pict3 from "../../../assets/Blog/SAFMC/SAFMC_PART5/Detail/3.webp";
import Pict4 from "../../../assets/Blog/SAFMC/SAFMC_PART5/Detail/4.webp";
import Pict5 from "../../../assets/Blog/SAFMC/SAFMC_PART5/Detail/5.webp";
import Pict6 from "../../../assets/Blog/SAFMC/SAFMC_PART5/Detail/6.webp";
import Pict7 from "../../../assets/Blog/SAFMC/SAFMC_PART5/Detail/7.webp";

import Gallery1 from "../../../assets/Blog/SAFMC/SAFMC_PART5/Gallery/1.webp";
import Gallery2 from "../../../assets/Blog/SAFMC/SAFMC_PART5/Gallery/2.webp";
import Gallery3 from "../../../assets/Blog/SAFMC/SAFMC_PART5/Gallery/3.webp";
import Gallery4 from "../../../assets/Blog/SAFMC/SAFMC_PART5/Gallery/4.webp";
import Gallery5 from "../../../assets/Blog/SAFMC/SAFMC_PART5/Gallery/5.webp";
import Gallery6 from "../../../assets/Blog/SAFMC/SAFMC_PART5/Gallery/6.webp";
import Gallery7 from "../../../assets/Blog/SAFMC/SAFMC_PART5/Gallery/7.webp";
import Gallery8 from "../../../assets/Blog/SAFMC/SAFMC_PART5/Gallery/8.webp";
import Gallery9 from "../../../assets/Blog/SAFMC/SAFMC_PART5/Gallery/9.webp";
import Gallery10 from "../../../assets/Blog/SAFMC/SAFMC_PART5/Gallery/10.webp";
import Gallery11 from "../../../assets/Blog/SAFMC/SAFMC_PART5/Gallery/11.webp";
import Gallery12 from "../../../assets/Blog/SAFMC/SAFMC_PART5/Gallery/12.webp";
import Gallery13 from "../../../assets/Blog/SAFMC/SAFMC_PART5/Gallery/13.webp";
import Gallery14 from "../../../assets/Blog/SAFMC/SAFMC_PART5/Gallery/14.webp";
import Gallery15 from "../../../assets/Blog/SAFMC/SAFMC_PART5/Gallery/15.webp";
import Gallery16 from "../../../assets/Blog/SAFMC/SAFMC_PART5/Gallery/16.webp";
import Gallery17 from "../../../assets/Blog/SAFMC/SAFMC_PART5/Gallery/17.webp";
import Gallery18 from "../../../assets/Blog/SAFMC/SAFMC_PART5/Gallery/18.webp";
import Gallery19 from "../../../assets/Blog/SAFMC/SAFMC_PART5/Gallery/19.webp";
import Gallery20 from "../../../assets/Blog/SAFMC/SAFMC_PART5/Gallery/20.webp";
import Gallery21 from "../../../assets/Blog/SAFMC/SAFMC_PART5/Gallery/21.webp";
import Gallery22 from "../../../assets/Blog/SAFMC/SAFMC_PART5/Gallery/22.webp";
import Gallery23 from "../../../assets/Blog/SAFMC/SAFMC_PART5/Gallery/23.webp";
import Gallery24 from "../../../assets/Blog/SAFMC/SAFMC_PART5/Gallery/24.webp";

const SAFMCPART5: React.FC = () => {
    const [isMd, setIsMd] = useState(window.matchMedia('(min-width: 900px)').matches);
    const [showScrollToTop, setShowScrollToTop] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
    const [selectedAlt, setSelectedAlt] = useState('');
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        setProgress(30);
        setTimeout(() => {
            setProgress(100);
        }, 600);
    }, []);
  
    const openModal = (imageUrl, imageAlt) => {
        setSelectedImage(imageUrl);
        setSelectedAlt(imageAlt);
        setModalVisible(true);
    };
  
    const closeModal = () => {
        setSelectedImage('');
        setSelectedAlt('');
        setModalVisible(false);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const mediaQuery = window.matchMedia('(min-width: 900px)');
        const updateIsMd = () => {
            setIsMd(mediaQuery.matches);
        };

        mediaQuery.addListener(updateIsMd);
        return () => {
            mediaQuery.removeListener(updateIsMd);
        };
    }, []);

    const handleScroll = () => {
        if (window.scrollY > 350) {
            setShowScrollToTop(true);
        } else {
            setShowScrollToTop(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const arrowTransition = useTransition(showScrollToTop, {
        from: { opacity: 0, transform: 'translateY(100%)' },
        enter: { opacity: 1, transform: 'translateY(0%)' },
        leave: { opacity: 0, transform: 'translateY(100%)' },
        config: { tension: 300, friction: 20 },
      });

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, {passive: false});
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    usePreventZoom();

    return(
        <div className='App'>
            <NavbarDetailBlog/>
            <div className='pt-20 min-w-screen font-condensed text-textGrey'>
                {/* Loading Bar */}
                <LoadingBar color="#8C001f" progress={progress} onLoaderFinished={() => setProgress(0)} />
                <section className='hidden w-64 mr-10 group transform hover:translate-y-[-5px] transition-transform duration-300 ease-in-out'>
                        <script type='text/javascript' id='clustrmaps' src='//cdn.clustrmaps.com/map_v2.js?cl=e1e1e1&w=a&t=tt&d=mIBEHA96Sa9wc29y0yefyCb9wP8YgephePOwDaQRDk0&co=ffffff&cmo=8c001f&cmn=d4201e&ct=394048'></script>
                </section>
                <HeaderDetailBlog backgroundImage={Header} title="Spend Some Days in Batam" date="24" year="December, 2023" backgroundPosition="center center"></HeaderDetailBlog>
                <div className='author-article text-end pr-4 pt-8 pb-4 text-xs'>
                    <p className='font-condensed text-textGrey'>by Mohammad Rifqi Farhansyah | Singapore Amazing Flying Machine Competition 2023</p>
                </div>
                <div className='article-content-1 md:flex md:flex-row flex flex-col md:px-10 px-10 gap-x-4 gap-y-4 md:mb-16 mb-8'>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Pict1, 'Enjoy the meal at Golden Prawn 555, Batam')}>
                            <img
                                src={Pict1}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="Enjoy the meal at Golden Prawn 555, Batam"
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto'>
                            <p className='text-lg font-condensed text-start'><i>Enjoy the meal at Golden Prawn 555, Batam</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                    <div className='article-content__text md:w-2/3 w-full h-auto text-justify'>
                        <p className='text-2xl'>Those tense days have come to an end, and now we are lazyly awaiting the announcement of the results in three days. Before heading home, we have decided to take a short recreational break in Batam and Singapore, making the most of our free time while anticipating the Awarding Ceremony day.</p>
                        <br/>
                        <p className='text-lg'>On Wednesday, March 29, 2023, we were invited by Kak Zeke's parents to have a meal with their extended family at Golden Prawn 555 in Batam. This is a seafood restaurant where we could directly choose live seafood to be cooked according to our preferences. Sakti and I hesitated to make any choices, knowing that everything would be covered by Kak Zeke's parents. Suddenly, a variety of dishes arrived at our table, including fish, crab, shrimp, clams, and even oysters. We started selecting and enjoying the dishes while sharing stories about the just-concluded competition. Laughter filled the air, and we were so engrossed in our conversation that we didn't realize almost all the dishes were gone.</p>
                    </div>
                </div>
                <div className='article-content-1 md:flex md:flex-row flex flex-col md:px-10 px-10 gap-x-4 gap-y-4 md:mb-20 mb-8'>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery1, 'Golden Prawn 555`s View (Part 1).')}>
                            <img
                                src={Gallery1}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="Golden Prawn 555`s View (Part 1)."
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto'>
                            <p className='text-lg font-condensed text-start'><i>Golden Prawn 555`s View (Part 1).</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery2, 'Golden Prawn 555`s View (Part 2).')}>
                            <img
                                src={Gallery2}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="Golden Prawn 555`s View (Part 2)."
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto'>
                            <p className='text-lg font-condensed text-start'><i>Golden Prawn 555`s View (Part 2).</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery3, 'Golden Prawn 555`s View (Part 3).')}>
                            <img
                                src={Gallery3}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="Golden Prawn 555`s View (Part 3)."
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto'>
                            <p className='text-lg font-condensed text-start'><i>Golden Prawn 555`s View (Part 3).</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                </div>
                <div className='article-break-1 flex justify-center items-center flex-col md:mb-36 mb-20'>
                    <hr className='w-5/6 mt-4 md:mb-12 mb-4  border-t border-b border-solid'/>
                    <p className='md:text-5xl text-2xl font-bold text-black md:px-44 px-10'>"Wow, this is one of the best seafood dishes I have ever eaten."</p>
                    <hr className='w-5/6 mb-4 md:mt-12 mt-4 border-t border-b border-solid'/>
                </div>
                <div className='article-content-1 md:flex md:flex-row flex flex-col md:px-10 px-10 gap-x-4 gap-y-4 md:mb-20 mb-8'>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery4, 'Enjoy the seafood (Part 1).')}>
                            <img
                                src={Gallery4}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="Enjoy the seafood (Part 1)."
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto'>
                            <p className='text-lg font-condensed text-start'><i>Enjoy the seafood (Part 1).</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery5, 'Enjoy the seafood (Part 2).')}>
                            <img
                                src={Gallery5}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="Enjoy the seafood (Part 2)."
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto'>
                            <p className='text-lg font-condensed text-start'><i>Enjoy the seafood (Part 2).</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery6, 'Enjoy the seafood (Part 3).')}>
                            <img
                                src={Gallery6}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="Enjoy the seafood (Part 3)."
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto'>
                            <p className='text-lg font-condensed text-start'><i>Enjoy the seafood (Part 3).</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                </div>
                <div className='bg-fixed bg-center w-full h-96 md:mb-36 mb-20' style={{ backgroundImage: `url(${Pict2})`, backgroundSize: 'cover',  }}>
                </div>
                <div className='article-content-1 md:flex md:flex-row flex flex-col md:px-10 px-10 gap-x-4 gap-y-4 md:mb-16 mb-8'>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Pict3, "The beauty of traditional Batak houses 'Rumah Limas Potong'")}>
                            <img
                                src={Pict3}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="The beauty of traditional Batak houses 'Rumah Limas Potong'"
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto'>
                            <p className='text-lg font-condensed text-start'><i>The beauty of traditional Batak houses 'Rumah Limas Potong'</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                    <div className='article-content__text md:w-2/3 w-full h-auto text-justify'>
                        <p className='text-lg'>On Thursday, March 30, 2023, we explored the surroundings of Zeke Hotel. There were several interesting tourist attractions, such as Marina Beach and Rumah Limas Potong (traditional house from the Batam region). Marina Beach is renowned for its exotic and luxurious atmosphere, featuring fine white sand that sets it apart from other beaches. This uniqueness often captivates the attention of both domestic and international tourists. Meanwhile, our curiosity was also drawn to one particular house, which turned out to be Rumah Limas Potong, a traditional house from the Batam region. This house is a raised structure extending backward. It is named Rumah Limas Potong because its roof resembles a segmented pyramid. </p>
                    </div>
                </div>
                <div className='article-content-1 md:flex md:flex-row flex flex-col md:px-10 px-10 gap-x-4 gap-y-4 md:mb-20 mb-8'>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery7, "Marina Beach 🤩")}>
                            <img
                                src={Gallery7}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="Marina Beach 🤩"
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>Marina Beach </i>🤩</p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery8, "The houses around Zeke Hotel.")}>
                            <img
                                src={Gallery8}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="The houses around Zeke Hotel."
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>The houses around Zeke Hotel. </i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery9, "Such an amazing nature scenery.")}>
                            <img
                                src={Gallery9}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="Such an amazing nature scenery."
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>Such an amazing nature scenery. </i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                </div>
                <div className='article-break-1 flex justify-center items-center flex-col md:mb-36 mb-20'>
                    <hr className='w-5/6 mt-4 md:mb-12 mb-4  border-t border-b border-solid'/>
                    <p className='md:text-5xl text-2xl font-bold text-black md:px-44 px-10'>"The natural beauty of Indonesia is truly unparalleled. Anyone who visits here is bound to instantly fall in love with this country."</p>
                    <hr className='w-5/6 mb-4 md:mt-12 mt-4 border-t border-b border-solid'/>
                </div>
                <div className='article-content-1 md:flex md:flex-row flex flex-col md:px-10 px-10 gap-x-4 gap-y-4 md:mb-20 mb-8'>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery10, "The antiquated vista (Part 1).")}>
                            <img
                                src={Gallery10}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="The antiquated vista (Part 1)."
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>The antiquated vista (Part 1). </i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery11, "The antiquated vista (Part 2).")}>
                            <img
                                src={Gallery11}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="The antiquated vista (Part 2)."
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>The antiquated vista (Part 2). </i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery12, "The antiquated vista (Part 3).")}>
                            <img
                                src={Gallery12}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="The antiquated vista (Part 3)."
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>The antiquated vista (Part 3). </i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                </div>
                <div className='bg-fixed bg-center w-full h-96 md:mb-36 mb-20' style={{ backgroundImage: `url(${Pict4})`, backgroundSize: 'cover',  }}>
                </div>
                <div className='article-content-1 md:flex md:flex-row flex flex-col md:px-10 px-10 gap-x-4 gap-y-4 md:mb-16 mb-8'>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Pict5, "I am waiting for the time to break my fast.")}>
                            <img
                                src={Pict5}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="I am waiting for the time to break my fast."
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>I am waiting for the time to break my fast. </i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                    <div className='article-content__text md:w-2/3 w-full h-auto text-justify'>
                        <p className='text-lg'>Today marks our last day of breaking the fast in Batam, as on Friday, March 31, 2023, and Saturday, April 1, 2023, we will be heading to Singapore (We will back to Batam everyday, but it seems like we will arrived at 20:00 WIB, so we will break our fast at Singapore). On Friday, we plan to explore Singapore, and then attend the awarding ceremony on Saturday. Therefore, we decided to have our iftar (breaking fast) together in my room with Sakti. We came up with the idea of ordering food from around Zeke Hotel. Eventually, we ordered Laksa noodles through an online delivery app. While waiting for the Maghrib call to prayer, we played cards. Whatever game we played, well, let's just say we weren't too skilled in that department. Meanwhile, Sakti didn't participate in card games at all. He was solely focused on the laptop screen in front of him. Before we knew it, the Maghrib call to prayer echoed, and we broke our fast today by starting with a prayer. </p>
                    </div>
                </div>
                <div className='article-content-1 md:flex md:flex-row flex flex-col md:px-10 px-10 gap-x-4 gap-y-4 md:mb-20 mb-8'>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery13, "He waits for his loss.")}>
                            <img
                                src={Gallery13}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="He waits for his loss."
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>He waits for his loss. </i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery14, "Sakti remains busy working on his 'Operating Systems' assignment.")}>
                            <img
                                src={Gallery14}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="Sakti remains busy working on his 'Operating Systems' assignment."
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>Sakti remains busy working on his 'Operating Systems' assignment. </i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery15, "It's time to break the fast.")}>
                            <img
                                src={Gallery15}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="It's time to break the fast."
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>It's time to break the fast. </i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                </div>
                <div className='article-break-1 flex justify-center items-center flex-col md:mb-36 mb-20'>
                    <hr className='w-5/6 mt-4 md:mb-12 mb-4  border-t border-b border-solid'/>
                    <p className='md:text-5xl text-2xl font-bold text-black md:px-44 px-10'>"There are a big ships and small ships. But the best ship of all is friendship."</p>
                    <hr className='w-5/6 mb-4 md:mt-12 mt-4 border-t border-b border-solid'/>
                </div>
                <div className='article-content-1 md:flex md:flex-row flex flex-col md:px-10 px-10 gap-x-4 gap-y-4 md:mb-20 mb-8'>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery16, "Woman in rain..")}>
                            <img
                                src={Gallery16}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="Woman in rain.."
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>Woman in rain.. </i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery17, "Man in the rain")}>
                            <img
                                src={Gallery17}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="Man in the rain"
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>Man in the <s>middle</s> rain</i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery18, "Woman in rain..")}>
                            <img
                                src={Gallery18}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="Woman in rain.."
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>Woman in rain.. </i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                </div>
                <div className='bg-fixed bg-center w-full h-96 md:mb-36 mb-20' style={{ backgroundImage: `url(${Pict6})`, backgroundSize: 'cover',  }}>
                </div>
                <div className='article-content-1 md:flex md:flex-row flex flex-col md:px-10 px-10 gap-x-4 gap-y-4 md:mb-20 mb-8'>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Pict7, "Returning to savor Indonesian cuisine in Batam.")}>
                            <img
                                src={Pict7}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="Returning to savor Indonesian cuisine in Batam."
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>Returning to savor Indonesian cuisine in Batam. </i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                    <div className='article-content__text md:w-2/3 w-full h-auto text-justify'>
                        <p className='text-lg'>Today (Friday, March 31, 2023), we will return to Singapore for some sightseeing. But before that, we enjoyed a pre-dawn meal (sahur) provided by the staff at Zeke Hotel. The Chicken Soto here is delicious, even better than the one I've had in Magelang (my hometown). While having our sahur, we discussed and decided on the destinations we wanted to visit. Farrel and Yoga suggested some places based on their previous vacations to Singapore. As Sakti and I were first-timers tourist in Singapore, we followed the recommendations of Yoga and Farrel. Finally, our chosen tourist destinations were set.</p>
                        <div className='article-break-1 flex justify-start items-center flex-col'>
                            <hr className='w-full mt-4 mb-4  border-t border-b border-solid'/>
                        </div>
                        <p className='text-lg font-bold'><i>to be continued...</i></p>
                    </div>
                </div>
                <div className='article-content-1 md:flex md:flex-row flex flex-col md:px-10 px-10 gap-x-4 gap-y-4 md:mb-20 mb-8'>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery19, "Programmer gang.")}>
                            <img
                                src={Gallery19}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="Programmer gang."
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>Programmer gang. </i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery20, "Watermelon is our favorite!")}>
                            <img
                                src={Gallery20}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="Watermelon is our favorite!"
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>Watermelon is our favorite! </i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery21, "Pilot gang.")}>
                            <img
                                src={Gallery21}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="Pilot gang."
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>Pilot gang. </i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                </div>
                <div className='article-content-1 md:flex md:flex-row flex flex-col md:px-10 px-10 gap-x-4 gap-y-4 md:mb-20 mb-8'>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery22, "The meats.")}>
                            <img
                                src={Gallery22}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="The meats."
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>The meats. </i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery23, "The vegetables.")}>
                            <img
                                src={Gallery23}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="The vegetables."
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>The vegetables. </i></p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                    <div className='article-content__image space-y-4 md:w-1/3 w-full h-auto'>
                        <div className="relative overflow-hidden group shadow-lg cursor-pointer" onClick={() => openModal(Gallery24, "WATERMELONNN 🥰")}>
                            <img
                                src={Gallery24}
                                className='w-full h-auto cursor-pointer transform transition-transform duration-300 group-hover:scale-105'
                                alt="WATERMELONNN 🥰"
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="bg-white bg-opacity-70 opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                                    <FaEye className="text-textRed text-5xl" />
                                </div>
                            </div>
                        </div>
                        <div className='article-content__image__desc   w-full h-auto md:px-0'>
                            <p className='text-lg font-condensed text-start'><i>WATERMELONNN </i>🥰</p>
                            <p className='text-sm font-condensed text-start'><i>©2023 Rifqi</i></p>
                        </div>
                    </div>
                </div>
                <div className='article-break-1 flex justify-center items-center flex-col md:mb-8 mb-4'>
                    <hr className='w-5/6 mt-4 md:mb-12 mb-4  border-t border-b border-solid'/>
                    <p className='md:text-5xl text-2xl font-bold text-black md:px-44 px-10'>"This is why I called this ramadhan, as my best ramadhan so far."</p>
                    <hr className='w-5/6 mb-4 md:mt-12 mt-4 border-t border-b border-solid'/>
                </div>
            </div>
            <div className='flex justify-center items-center px-12 md:mb-8 mb-4 gap-x-8'>
                <Link to="/safmc/safmc-part4" className='md:text-xl text-lg text-textRed font-bold flex items-center hover:scale-105'>
                    <FaAngleDoubleLeft className='w-3 h-3 text-rgba-28-5-179 inline-block mr-1' />
                    PREV 
                </Link>
                <Link to="/safmc/safmc-part6" className='md:text-xl text-lg text-textRed font-bold flex items-center hover:scale-105'>
                    NEXT 
                    <FaAngleDoubleRight className='w-3 h-3 text-rgba-28-5-179 inline-block ml-1' />
                </Link>
            </div>
            <div className='bg-fixed w-full h-96' style={{ backgroundImage: `url(${Gallery14})`, backgroundSize: 'cover',  }}>
            </div>
            <CommentListExample postId={5}/>
            <ImageModal
                showModal={modalVisible}
                closeModal={closeModal}
                imageUrl={selectedImage}
                imageAlt={selectedAlt}
            />
            <Footer/>
            {arrowTransition((style, item) =>
                item ? (
                <animated.div
                    style={style}
                    className="fixed bottom-5 right-5 cursor-pointer z-10"
                    onClick={scrollToTop}
                >
                    <FaArrowCircleUp
                    size={isMd ? 32 : 24}
                    className="text-[#c0c5ca] hover:scale-110"
                    />
                </animated.div>
                ) : null
            )}
        </div>
    );
};

export default SAFMCPART5