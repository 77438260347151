import React, {useEffect, useState} from 'react';
import {FaUser, FaComments} from 'react-icons/fa';
interface BlogPostProps {
  postUrl: string;
  imageUrl: string;
  title: string;
  description: string;
  category: string;
  authorUrl: string;
  authorName: string;
  dateDay: number;
  dateMonth: string;
  backgroundColor?: string;
  textColor?: string;
  categoryTop?: string;
  postId: number;
}

const BlogPost: React.FC<BlogPostProps> = ({
  postUrl,
  imageUrl,
  title,
  description,
  category,
  authorUrl,
  authorName,
  dateDay,
  dateMonth,
  backgroundColor = 'bg-red-500',
  textColor = 'text-red-500',
  categoryTop = 'top-48',
  postId,
}) => {

  const [commentCount, setCommentCount] = useState<number | null>(null);

  useEffect(() => {
    const fetchCommentCount = async () => {
      try {
        const response = await fetch(`https://api-rifqifarhansyah-id.onrender.com/api/commentCount/${postId}`);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        setCommentCount(data.commentCount);
      } catch (error) {
        console.error('Error fetching comment count:', error);
        setCommentCount(0); // Set to a default value indicating an error
      }
    };
  
    fetchCommentCount();
  }, [postId]);

  return (
    <a href={postUrl} className="article-card font-condensed">
      <article className="border shadow-md w-80 h-auto relative overflow-hidden transition-transform transform-gpu duration-200 ease-out hover:shadow-2xl rounded-xl"> {/*hover:translate-y-[-10px]*/}
        <div className="et_pb_image_container relative transition-transform transform-gpu duration-300 hover:scale-110 overflow-hidden">
          <a href={postUrl} className="entry-featured-image-url ">
            <img
              decoding="async"
              loading="lazy"
              src={imageUrl}
              alt={title}
              className="w-full h-auto"
            />
          </a>
        </div>
        <div className={`article-card__date absolute top-2 right-2 ${backgroundColor} text-white w-12 h-12 flex flex-col rounded-full box-border text-center items-center align-center justify-center`}>
            <span className="article-card__day font-bold">{dateDay}</span>
            <span className="article-card__month text-xs">{dateMonth}</span>
        </div>
        <div className='article-card__category'>
            <a href={category} rel="tag" className={`article-card__category absolute ${categoryTop} left-0 ${backgroundColor} text-white py-1 px-2`}>
                {category}
            </a>
        </div>
        <div className="article-card__content pt-8 px-4 bg-white">
            <h2 className="entry-title article-card__title text-2xl font-bold text-start pl-2">
                <a href={postUrl}>{title}</a>
            </h2>
            <p className='text-sm text-start pl-2 text-textGrey mt-1 mb-2 font-extralight'>{description} <span className={`hover:underline font-bold ${textColor}`}>READ MORE</span></p>
            <div className='flex flex-row'>
              <div className="article-card__meta text-xs text-start pl-2 text-center align-center pb-4 pt-4 text-textGrey">
                  <span className="author vcard article-card__author">
                  <FaUser className="mr-1 inline-block justify-center rounded-full mb-0.5" />
                  <a href={authorUrl} title={`Posts by ${authorName}`} rel="author">
                      {authorName}
                  </a>
                  </span>
              </div>
              <div className="article-card__meta text-xs text-start pl-2 text-center align-center pb-4 pt-4 text-textGrey">
                <span className="author vcard article-card__author">
                  <FaComments className="mr-1 inline-block justify-center mb-0.5" />
                  <span>
                    {commentCount !== null
                      ? `${commentCount} ${commentCount > 1 ? 'comments' : 'comment'}`
                      : 'Loading...'}
                  </span>
                </span>
              </div>
            </div>
        </div>
        </article>
    </a>
    );
};

export default BlogPost;
