import React, { useEffect, useState } from 'react';
import logoITB from '../assets/itb.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { FaGithub, FaLinkedin } from 'react-icons/fa';

const External: React.FC = () => {
    const [isMd, setIsMd] = useState(window.matchMedia('(min-width: 67.5rem)').matches);

    useEffect(() => {
        const mediaQuery = window.matchMedia('(min-width: 67.5rem)');
        const updateIsMd = () => {
            setIsMd(mediaQuery.matches);
        };
    
        mediaQuery.addListener(updateIsMd);
        return () => {
            mediaQuery.removeListener(updateIsMd);
        };
    }, []);

    return(
        <div >
            {isMd ? (
                <div className='flex justify-center items-center md:flex-row mt-10 mb-10 w-[65.625rem] h-auto'>
                    <section className='w-64 mr-10 group transform hover:translate-y-[-5px] transition-transform duration-300 ease-in-out'>
                        <script type='text/javascript' id='clustrmaps' src='//cdn.clustrmaps.com/map_v2.js?cl=e1e1e1&w=a&t=tt&d=mIBEHA96Sa9wc29y0yefyCb9wP8YgephePOwDaQRDk0&co=ffffff&cmo=8c001f&cmn=d4201e&ct=394048'></script>
                    </section>
                    <div className=' flex flex-col justify-center items-center md:items-center' >
                        <br/>
                        <p className='text-textGrey font-condensed text-center font-extrabold ' >
                            EXTERNAL LINKS
                        </p>
                        <br/>
                            <div className='flex flex-row space-x-5 justify-center items-center'>
                                <a href="https://github.com/rifqifarhansyah" alt="Rifqi Github"  title="Github">
                                    <FaGithub className='text-textGrey hover:text-textRed'/>
                                </a>
                                <a href="https://www.linkedin.com/in/mohammadrifqifarhansyah/" alt="Rifqi Linkedin"  title="Linkedin">
                                    <FaLinkedin className='text-textGrey hover:text-textRed'/>
                                </a>
                                <a href="https://twitter.com/rifqifrhnsyah" alt="Rifqi X"  title="X">
                                    <FontAwesomeIcon className='text-textGrey hover:text-textRed' icon={faXTwitter} />
                                </a>
                            </div>
                        <br/>
                        <br/>
                    </div>
                    <div className='w-36 ml-14 mr-28 group'>
                        <a href='https://www.itb.ac.id/'>
                            <img src={logoITB} alt='ITB`s Logo' title='ITB`s Logo' width="180" height="180" className='transform hover:translate-y-[-5px] transition-transform duration-300 ease-in-out' loading='lazy'></img>
                        </a>
                    </div>
                </div>
            ) : (
                <div className='flex items-center flex-col mb-10 justify-center md:w-[95vw] sm:w-[93vw] h-auto'>
                    <div className=' flex flex-col justify-center items-center ' >
                        <br/>
                        <p className='text-textGrey font-condensed text-center font-extrabold ' >
                            EXTERNAL LINKS
                        </p>
                        <br/>
                            <div className='flex flex-row space-x-5 justify-center items-center'>
                                <a href="https://github.com/rifqifarhansyah" alt="Rifqi Github"  title="Github">
                                    <FaGithub className='text-textGrey hover:text-textRed'/>
                                </a>
                                <a href="https://www.linkedin.com/in/mohammadrifqifarhansyah/" alt="Rifqi Linkedin"  title="Linkedin">
                                    <FaLinkedin className='text-textGrey hover:text-textRed'/>
                                </a>
                                <a href="https://twitter.com/rifqifrhnsyah" alt="Rifqi X"  title="X">
                                    <FontAwesomeIcon className='text-textGrey hover:text-textRed' icon={faXTwitter} />
                                </a>
                            </div>
                        <br/>
                        <br/>
                    </div>
                    <section className='w-64 group transform hover:translate-y-[-5px] transition-transform duration-300 ease-in-out'>
                        <script type='text/javascript' id='clustrmaps' src='//cdn.clustrmaps.com/map_v2.js?cl=e1e1e1&w=a&t=tt&d=mIBEHA96Sa9wc29y0yefyCb9wP8YgephePOwDaQRDk0&co=ffffff&cmo=8c001f&cmn=d4201e&ct=394048'></script>
                    </section>
                </div>
            )}
        </div>
    );
};

export default External